import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./PageHeader";

import { useEffect, useState } from "react";
import { fetchDataWithToken } from "src/config/axios.config";
import AccountBalance from "./AccountBalance";
import AccountSecurity from "./AccountSecurity";
import RecentOrders from "./RecentOrders";
import Wallets from "./Wallets";

function DashboardCrypto() {
  const [getData, setData] = useState(null);
  useEffect(() => {
    fetchDataWithToken("user/dashboard", setData);
  }, []);
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 4,
        }}
        rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
        columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
        className="responsive-container"
      >
        <Grid item xs={12}>
          <AccountBalance data={getData} setData={setData} />
        </Grid>
        <Grid item lg={8} xs={12}>
          <Wallets wallets={getData?.wallet} setData={setData} />
        </Grid>
        <Grid item lg={4} xs={12}>
          <AccountSecurity user_info={getData?.user} />
        </Grid>
        <Grid item xs={12}>
          <RecentOrders
            transactions={getData?.transactions}
            setData={setData}
          />
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}

export default DashboardCrypto;
