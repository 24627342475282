import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockClockIcon from "@mui/icons-material/LockClock";
import PasswordIcon from "@mui/icons-material/Password";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import copy from "copy-text-to-clipboard";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemText,
  alpha,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchDataWithToken } from "src/config/axios.config";

function ProfileSettings() {
  const [getData, setData] = useState(null);

  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    fetchDataWithToken("user/accounts", setData);
  }, []);
  function getFirstLetters(str) {
    const firstLetters = str
      ?.split(" ")
      ?.map((word) => word.charAt(0))
      ?.join("");

    return firstLetters;
  }
  const handleClickCopy = (text) => {
    copy(`${window.location.origin}/register?invitation_code=${text}`);
    Swal.fire({
      icon: "success",
      title: "Invitation Code Copied",
      showConfirmButton: false,
      timer: 3000,
      customClass:
        (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
          "GreenFieldsTheme") ||
        (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
          "NebulaFighterTheme") ||
        (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
          "DarkSpacesTheme") ||
        (localStorage.getItem("appTheme") == "PureLightTheme" &&
          "PureLightTheme") ||
        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
          "GreyGooseTheme") ||
        (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
          "PurpleFlowTheme"),
    });
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
        columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
        className="responsive-container"
        justifyContent="center"
      >
        <Grid item lg={6} xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              pt: 3,
              mb: 3,
            }}
          >
            <Typography variant="h3">{t("Profile")}</Typography>
          </Box>
          <Card
            sx={{
              px: 1,
              mb: 3,
            }}
          >
            <CardContent>
              <ul
                className={`profile-menu ${
                  (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                    "GreenFieldsTheme") ||
                  (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                    "NebulaFighterTheme") ||
                  (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                    "DarkSpacesTheme") ||
                  (localStorage.getItem("appTheme") == "PureLightTheme" &&
                    "PureLightTheme") ||
                  (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                    "GreyGooseTheme") ||
                  (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                    "PurpleFlowTheme")
                }`}
              >
                <li>
                  <NavLink to="/profile-settings/profile">
                    <AccountCircleIcon /> Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/profile-settings/edit-profile">
                    <HistoryEduIcon />
                    Edit Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/profile-settings/google-auth">
                    <LockClockIcon /> Google Auth
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/profile-settings/change-password">
                    <PasswordIcon /> Change Password
                  </NavLink>
                </li>
              </ul>
            </CardContent>
          </Card>
          <Card
            sx={{
              position: "relative",
              p: 3,
              mb: 4,
            }}
          >
            <Box mb={2} display="flex" alignItems="center">
              <Avatar
                variant="rounded"
                sx={{
                  fontSize: `${theme.typography.pxToRem(16)}`,
                  background: `${theme.colors.alpha.black[100]}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.alpha.black[100]
                  )}`,
                  borderRadius: `${theme.general.borderRadiusSm}`,
                  width: 95,
                  height: 95,
                }}
              >
                {getFirstLetters(getData?.user_info?.name)}
              </Avatar>
              <Box
                sx={{
                  width: "100%",
                }}
                ml={1.5}
              >
                <Link
                  color="text.primary"
                  underline="none"
                  sx={{
                    transition: `${theme.transitions.create(["color"])}`,
                    fontSize: `${theme.typography.pxToRem(17)}`,

                    "&:hover": {
                      color: `${theme.colors.primary.main}`,
                    },
                  }}
                  variant="h4"
                >
                  {getData?.user_info?.name}
                </Link>
                <Typography gutterBottom variant="subtitle2">
                  {getData?.user_info?.user_name}
                </Typography>
              </Box>
            </Box>
            <label>Invitation Code</label>
            <Grid
              className={`copy-code ${
                (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                  "GreenFieldsTheme") ||
                (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                  "NebulaFighterTheme") ||
                (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                  "DarkSpacesTheme") ||
                (localStorage.getItem("appTheme") == "PureLightTheme" &&
                  "PureLightTheme") ||
                (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                  "GreyGooseTheme") ||
                (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                  "PurpleFlowTheme")
              }`}
            >
              <span className="text">{`${window.location.origin}/register?invitation_code=${getData?.invitation_code}`}</span>
              <span
                className={`copy ${
                  (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                    "GreenFieldsTheme") ||
                  (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                    "NebulaFighterTheme") ||
                  (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                    "DarkSpacesTheme") ||
                  (localStorage.getItem("appTheme") == "PureLightTheme" &&
                    "PureLightTheme") ||
                  (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                    "GreyGooseTheme") ||
                  (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                    "PurpleFlowTheme")
                }`}
                onClick={() => handleClickCopy(getData?.invitation_code)}
              >
                Copy
              </span>
            </Grid>
            <Card
              elevation={0}
              sx={{
                mt: 2,
                mb: 3,
                background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
              }}
            >
              <List dense>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={t("Email") + ":"}
                  />
                  <Typography variant="subtitle1">
                    {getData?.user_info?.email}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={t(" Username") + ":"}
                  />
                  <Typography variant="subtitle1">
                    {" "}
                    {getData?.user_info?.user_name}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={t("Email Verified") + ":"}
                  />
                  <Typography variant="subtitle1">
                    {getData?.user_info?.email_verified == 1 ? "Yes" : "No"}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={t("Google Auth") + ":"}
                  />
                  <Typography variant="subtitle1">
                    {getData?.user_info?.g2f_enabled == 1
                      ? "Enable"
                      : "Disable"}
                  </Typography>
                </ListItem>
              </List>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProfileSettings;
