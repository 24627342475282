import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockClockIcon from "@mui/icons-material/LockClock";
import PasswordIcon from "@mui/icons-material/Password";
import PinIcon from "@mui/icons-material/Pin";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import copy from "copy-text-to-clipboard";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { fetchDataWithToken, userPrivateRequest } from "src/config/axios.config";
import { errorHandler } from "src/utils/errorHandler";
import Swal from "sweetalert2";
import * as Yup from "yup";

const BoxActions = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]}
    `
);
function GoogleAuth() {
  const [getData, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [removeSecret, setRemoveSecret] = useState(false);

  const { t } = useTranslation();
  const initialValues = {
    code: "",
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required(t("The code is required"))
      .matches(/^(?:[1-9]\d*|\d)$/, "The code must be number")
      .max(6, "The code maximum 6")
      .min(6, "The code minimum 6"),
  });
  useEffect(() => {
    fetchDataWithToken("user/accounts", setData);
  }, []);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const google_auth = useMutation(async (payload) => {
    try {
      const res = await userPrivateRequest.post(`user/g2f-secret-save`, payload);

      return res.data;
    } catch (error) {
      errorHandler(error);
    }
  });
  const handleSubmit = (values, actions) => {
    const data = {
      code: values.code,
      remove: 0,
      google2fa_secret: getData?.google2fa_secret,
    };
    google_auth
      .mutateAsync(data)
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: data?.message ?? "Successfully update profile",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
        setOpen(false);
        fetchDataWithToken("user/accounts", setData);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.message ?? "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      });
  };
  const handleSubmitRemove = (values, actions) => {
    const data = {
      code: values.code,
      remove: 1,
      google2fa_secret: getData?.google2fa_secret,
    };
    Swal.fire({
      title: "Remove",
      icon: "warning",
      html: "Do you want to remove google auth",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#06207c",
      cancelButtonColor: "#d33",
      customClass:
        (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
          "GreenFieldsTheme") ||
        (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
          "NebulaFighterTheme") ||
        (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
          "DarkSpacesTheme") ||
        (localStorage.getItem("appTheme") == "PureLightTheme" &&
          "PureLightTheme") ||
        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
          "GreyGooseTheme") ||
        (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
          "PurpleFlowTheme"),
    }).then((result) => {
      if (result.isConfirmed) {
        google_auth
          .mutateAsync(data)
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: data?.message ?? "Successfully update profile",
              showConfirmButton: false,
              timer: 3000,
              customClass:
                (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                  "GreenFieldsTheme") ||
                (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                  "NebulaFighterTheme") ||
                (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                  "DarkSpacesTheme") ||
                (localStorage.getItem("appTheme") == "PureLightTheme" &&
                  "PureLightTheme") ||
                (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                  "GreyGooseTheme") ||
                (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                  "PurpleFlowTheme"),
            });
            setRemoveSecret(false);
            fetchDataWithToken("user/accounts", setData);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: err?.message ?? "Something went wrong",
              showConfirmButton: false,
              timer: 3000,
              customClass:
                (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                  "GreenFieldsTheme") ||
                (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                  "NebulaFighterTheme") ||
                (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                  "DarkSpacesTheme") ||
                (localStorage.getItem("appTheme") == "PureLightTheme" &&
                  "PureLightTheme") ||
                (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                  "GreyGooseTheme") ||
                (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                  "PurpleFlowTheme"),
            });
          });
      }
    });
  };

  const handleClickCopy = (text) => {
    copy(text);
    Swal.fire({
      icon: "success",
      title: "Copied",
      showConfirmButton: false,
      timer: 3000,
      customClass:
        (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
          "GreenFieldsTheme") ||
        (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
          "NebulaFighterTheme") ||
        (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
          "DarkSpacesTheme") ||
        (localStorage.getItem("appTheme") == "PureLightTheme" &&
          "PureLightTheme") ||
        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
          "GreyGooseTheme") ||
        (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
          "PurpleFlowTheme"),
    });
  };
  const handleRemoveModal = () => {
    setRemoveSecret(true);
  };
  return (
    <Grid
      container
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
      columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
      className="responsive-container"
      justifyContent="center"
    >
      <Grid item lg={6} xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            pt: 3,
            mb: 3,
          }}
        >
          <Typography variant="h3">{t("Authentication")}</Typography>
        </Box>
        <Card
          sx={{
            px: 1,
            mb: 3,
          }}
        >
          <CardContent>
            <ul
              className={`profile-menu ${
                (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                  "GreenFieldsTheme") ||
                (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                  "NebulaFighterTheme") ||
                (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                  "DarkSpacesTheme") ||
                (localStorage.getItem("appTheme") == "PureLightTheme" &&
                  "PureLightTheme") ||
                (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                  "GreyGooseTheme") ||
                (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                  "PurpleFlowTheme")
              }`}
            >
              <li>
                <NavLink to="/profile-settings/profile">
                  <AccountCircleIcon /> Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile-settings/edit-profile">
                  <HistoryEduIcon />
                  Edit Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile-settings/google-auth">
                  <LockClockIcon /> Google Auth
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile-settings/change-password">
                  <PasswordIcon /> Change Password
                </NavLink>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card
          sx={{
            position: "relative",
            p: 3,
            mb: 4,
          }}
        >
          <Grid className="google-auth-wrap">
            <h3 className="auth-title">Google Authentication</h3>
            <Grid className="google-auth-body">
              <span className="icon">
                <PinIcon />
                {getData?.user_info?.g2f_enabled == 1 && (
                  <CheckCircleIcon className="checked" />
                )}
              </span>
              {getData?.user_info?.g2f_enabled == 1 ? (
                <p>
                  Keep your account secure by enabling two-factor
                  authentication. Each time you login in . you'll be require to
                  provide your password plus a randomly generate access code
                  Please on this option to enable two factor authentication at
                  log In.
                </p>
              ) : (
                <p>
                  Keep your account secure by enabling two-factor
                  authentication. Each time you login in . you'll be require to
                  provide your password plus a randomly generate access code
                </p>
              )}

              {getData?.user_info?.g2f_enabled == 1 ? (
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleRemoveModal}
                >
                  Remove google secret key
                </Button>
              ) : (
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Manage two factor authentication
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("Google Authentication")}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 3,
          }}
        >
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <p className="m-0">
                      Open your Google Authenticator app, and scan Your secret
                      code and enter the 6-digit code from the app into the
                      input field
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid className="qrcode-wrap">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData?.qrcode,
                        }}
                      />
                      <span
                        className={
                          localStorage.getItem("appTheme") ==
                            "GreenFieldsTheme" ||
                          localStorage.getItem("appTheme") ==
                            "NebulaFighterTheme" ||
                          localStorage.getItem("appTheme") == "DarkSpacesTheme"
                            ? "dark"
                            : ""
                        }
                      >
                        Scan qrcode or copy bellow code
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid className="copy-code">
                      <span className="text">{getData?.google2fa_secret}</span>
                      <span
                        onClick={() =>
                          handleClickCopy(getData?.google2fa_secret)
                        }
                        className={`copy ${
                          (localStorage.getItem("appTheme") ==
                            "GreenFieldsTheme" &&
                            "GreenFieldsTheme") ||
                          (localStorage.getItem("appTheme") ==
                            "NebulaFighterTheme" &&
                            "NebulaFighterTheme") ||
                          (localStorage.getItem("appTheme") ==
                            "DarkSpacesTheme" &&
                            "DarkSpacesTheme") ||
                          (localStorage.getItem("appTheme") ==
                            "PureLightTheme" &&
                            "PureLightTheme") ||
                          (localStorage.getItem("appTheme") ==
                            "GreyGooseTheme" &&
                            "GreyGooseTheme") ||
                          (localStorage.getItem("appTheme") ==
                            "PurpleFlowTheme" &&
                            "PurpleFlowTheme")
                        }`}
                      >
                        Copy
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="code"
                      component={TextField}
                      label={t("Enter 6 digit auth code")}
                      placeholder={t("Write your code here...")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BoxActions
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            color="primary"
                            type="button"
                            onClick={() => setOpen(false)}
                            fullWidth
                          >
                            {t("Close")}
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                          >
                            {t("Verify")}
                          </Button>
                        </Grid>
                      </Grid>
                    </BoxActions>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={removeSecret}
        onClose={() => setRemoveSecret(false)}
      >
        <DialogTitle
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("Google Authentication")}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 3,
          }}
        >
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmitRemove}
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <p className="m-0">
                      Open your Google Authenticator app and enter the 6-digit
                      code from the app into the input field
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="code"
                      component={TextField}
                      label={t("Code")}
                      placeholder={t("Write your code here...")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BoxActions
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            color="primary"
                            type="button"
                            onClick={() => setRemoveSecret(false)}
                            fullWidth
                          >
                            {t("Close")}
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                          >
                            {t("Remove Auth")}
                          </Button>
                        </Grid>
                      </Grid>
                    </BoxActions>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default GoogleAuth;
