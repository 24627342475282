import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";
import "src/mocks";

import "nprogress/nprogress.css";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import App from "src/App";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import store from "src/store";
import queryClient from "./config/queyClient.config";
import "./styles/styles.css";

ReactDOM.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SidebarProvider>
          <BrowserRouter>
            <ScrollTop />
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SidebarProvider>
      </Provider>
    </QueryClientProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
