import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockClockIcon from "@mui/icons-material/LockClock";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import PasswordIcon from "@mui/icons-material/Password";
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { privateRequest } from "src/config/axios.config";
import { errorHandler } from "src/utils/errorHandler";
import * as Yup from "yup";

function ChangePassword() {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required(
      t("The old password field is required")
    ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters in length")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one digit")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain a special character (!@#$%^&*(),.?:{}|<>)"
      )
      .required(t("The password field is required")),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        t("Both password fields need to be the same")
      )
      .required(t("This field is required")),
  });
  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const change_password = useMutation(async (payload) => {
    try {
      const res = await privateRequest.post(
        `admin/change-account-password`,
        payload
      );
      return res.data;
    } catch (error) {
      errorHandler(error);
    }
  });
  const handleSubmit = (values, actions) => {
    change_password
      .mutateAsync(values)
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: data?.message ?? "Successfully update profile",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.message ?? "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      });
  };
  return (
    <Grid
      container
      justifyContent="center"
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
      columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
      className="responsive-container"
    >
      <Grid item lg={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            pt: 3,
            mb: 3,
          }}
        >
          <Typography variant="h3">{t("Change Password")}</Typography>
        </Box>
        <Card
          sx={{
            px: 1,
            mb: 3,
          }}
        >
          <CardContent>
            <ul
              className={`profile-menu ${
                (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                  "GreenFieldsTheme") ||
                (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                  "NebulaFighterTheme") ||
                (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                  "DarkSpacesTheme") ||
                (localStorage.getItem("appTheme") == "PureLightTheme" &&
                  "PureLightTheme") ||
                (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                  "GreyGooseTheme") ||
                (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                  "PurpleFlowTheme")
              }`}
            >
              <li>
                <NavLink to="/admin/profile-settings/profile">
                  <AccountCircleIcon /> Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/profile-settings/google-auth">
                  <LockClockIcon /> Google Auth
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/profile-settings/change-password">
                  <PasswordIcon /> Change Password
                </NavLink>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card
          sx={{
            position: "relative",
            p: 3,
            mb: 4,
          }}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      type="password"
                      name="old_password"
                      component={TextField}
                      label={t("Old Password")}
                      placeholder={t("Write a password here...")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      type="password"
                      name="password"
                      component={TextField}
                      label={t("Password")}
                      placeholder={t("Write a old password here...")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      type="password"
                      name="confirm_password"
                      component={TextField}
                      label={t("Confirm password")}
                      placeholder={t("Confirm password here...")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      {t("Change Password")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ChangePassword;
