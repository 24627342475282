import { useCallback, useEffect, useState } from "react";

import { Card } from "@mui/material";
import useRefMounted from "src/hooks/useRefMounted";
import axios from "src/utils/axios";
import RecentOrdersTable from "./RecentOrdersTable";

function RecentOrders(props) {
  const isMountedRef = useRefMounted();
  const [cryptoOrders, setCryptoOrders] = useState([]);

  const getCryptoOrders = useCallback(async () => {
    try {
      const response = await axios.get("/api/crypto-orders");

      if (isMountedRef.current) {
        setCryptoOrders(response.data.cryptoOrders);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCryptoOrders();
  }, [getCryptoOrders]);
  return (
    <Card>
      <RecentOrdersTable
        transactions={props.transactions}
        setData={props.setData}
      />
    </Card>
  );
}

export default RecentOrders;
