const cnJSON = {
    Blueprints: 'Planos',
  'Extended Sidebar': 'Barra lateral extendida',
  'Accent Sidebar': 'Barra lateral de acento',
  'Accent Header': 'Encabezado de acento',
  'Boxed Sidebar': 'Barra lateral en caja',
  'Collapsed Sidebar': 'Barra lateral contraída',
  'Bottom Navigation': 'Navegación inferior',
  'Top Navigation': 'Navegación superior',
  'Lead Developer': 'Desarrollador principal',
  'Mega menu': 'Mega menú',
  Doctors: 'Doctores',
  Reports: 'Informes',
  'Custom dashboard built using the included components':
    'Panel de control personalizado construido con los componentes incluidos',
  Hospital: 'Hospital',
  Export: 'Exportar',
  'Last year': 'El año pasado',
  Expenses: 'Gastos',
  Products: 'Productos',
  Statistics: 'Estadísticas',
  General: 'General',
  Automation: 'Automatización',
  'Data Display': 'Visualización de datos',
  Calendar: 'Calendario',
  Mailbox: 'Buzón',
  Dashboards: 'Cuadros de mando',
  Analytics: 'Analítica',
  Banking: 'Bancario',
  Commerce: 'Comercio',
  Crypto: 'Cripto',
  Finance: 'Finanzas',
  Fitness: 'Aptitud física',
  Healthcare: 'Cuidado de la salud',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descripción del hospital',
  Helpdesk: 'Mesa de ayuda',
  Learning: 'Aprendiendo',
  Monitoring: 'Supervisión',
  Tasks: 'Tareas',
  Applications: 'Aplicaciones',
  'File Manager': 'Administrador de archivos',
  'Jobs Platform': 'Plataforma de trabajos',
  Messenger: 'Mensajero',
  'Projects Board': 'Junta de proyectos',
  Management: 'Administración',
  Users: 'Usuarios',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  Projects: 'Proyectos',
  Shop: 'Tienda',
  'Products List': 'Lista de productos',
  'View Product': 'Ver el producto',
  'Create Product': 'Crear producto',
  Invoices: 'Facturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticación',
  Login: 'Acceso',
  Basic: 'Básico',
  Cover: 'Cubrir',
  Register: 'Registrarse',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar contraseña',
  // Status: 'Estado',
  Foundation: 'Fundación',
  'Extra Pages': 'Páginas extra',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Próximamente',
  Maintenance: 'Mantenimiento',
  Overview: 'Visión general',
  'Layout Starters': 'Arrancadores de diseño',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentación',
  Help: 'Ayuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Soporte de contacto',
  Version: 'Versión',
  Search: 'Buscar',
  Notifications: 'Notificaciones',
  Settings: 'Ajustes',
  'Language Switcher': 'Selector de idioma',
  'Sign out': 'Desconectar',
  'Change Theme': 'Cambiar de tema',
  'View all notifications': 'Ver todas las notificaciones',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Panel de administración de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Vista previa en vivo',
  'Key Features': 'Características clave',
  dashboards: 'cuadros de mando',
  applications: 'aplicaciones',
  'management sections': 'secciones de gestión',
  'dark/light themes': 'temas oscuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración',


// new 

'Available Balance':'可用余额',
'2FA Authentication': '2FA认证',
'A Digital-Native asset manager for a digital-driven world': '面向数字驱动世界,属于您的数字原生资产管',
'AMOUNT': '量',
'Amount': '量',
'Account Security': '账户安全',
'Buy New Perpetual SIP': '购买永久的 SIP',
'Completed': '完成',
'Copyright © 2017-2024 Bitgrow. All Rights Reserved.': '版权所有 © 2017-2024 Bitgrow。保留所有权利。',
'Crypto Future Trading Investment Packages allow experienced investors to borrow funds and trade larger amounts of cryptocurrencies. This option is high-risk, high-reward, perfect for aggressive investors seeking substantial short-term gains.': '加密期货交易投资套餐允许有经验的投资者借入资金并交易大量加密货币。此选项是高风险、高回报的，非常适合寻求大量短期收益的激进投资者。',
'Crypto Spot Trading Investment Packages offer immediate cryptocurrency trades at current market prices. Ideal for rapid returns, this investment package gives real-time exposure to the volatile and potentially profitable world of cryptocurrency.': '加密现货交易投资套餐以当前市场价格提供即时加密货币交易。该投资套餐是快速回报的理想选择，可以实时接触加密货币的动荡和潜在利润世界。',
'Current Week': '本周',
'Description': '描述',
'DESCRIPTION': '描述',
'Designed to help your money grow': '帮助您的资金增长',
'Disabled': '关闭',
'Dive into the dynamic world of digital currencies with our comprehensive Crypto Investment, tailored for both novice and seasoned investors.': '通过我们全面的加密投资，潜入数字货币的动态世界，为新手和经验丰富的投资者量身定制。',
'Email': '电子邮件',
'Email Verification': '电子邮件验证',
'Email Verified': '电子邮件已验证',
'English': 'Chinese',
'Enter your password to continue with the current session': '输入您的密码以继续当前登录',
'Established in 2017, Bitgrow is a trading firm specializing in cryptocurrencies with an AUM of USD 10 million.': 'Bitgrow 成立于 2017 年，是一家专门从事加密货币的交易公司，资产管理规模为 1000 万美元。',
'Expected Weekly PNL [60:21:15:4]': '预计周度损益',
'Expected Yearly PNL [60:21:15:4]': '预计年度损益',
'Google Auth': '谷歌身份验证',
'Holding': '持有交易',
'Holding Packages offer a long-term investment strategy, allowing investors to buy and hold cryptocurrencies for potential future appreciation. Ideal for those with a patient approach and strong belief in the future of digital currencies.': '持有套餐提供长期投资策略，允许投资者购买和持有加密货币以获得潜在的未来升值。非常适合那些有耐心的方法和对数字货币的未来有坚定信念的人。',
'Inactive': '未使用',
'Investment': '投资交易',
"Investment Packages provide structured and diverse investment solutions, designed to grow your capital over time. Tailored to meet various risk-reward profiles, they promise the potential for lucrative returns through strategic asset allocation.": '投资套餐提供结构化和多样化的投资解决方案，旨在随着时间的推移增加您的资本。它们专为满足各种风险回报状况而量身定制，承诺通过战略资产配置获得丰厚回报的潜力。',
'Invitation Code': '邀请码',
"Keep your account secure by enabling two-factor authentication. Each time you login in . you'll be require to provide your password plus a randomly generate access code": '通过启用双重身份验证来确保您的帐户安全。每次登录时,您需要提供密码和随机生成的访问代码',
'Language Switcher': '语言切换器',
'Leverage your profits and amplify your earning potential with our Future Trading Investment, designed for the aggressive, risk-tolerant investor.': '通过我们的未来交易投资，利用您的利润并扩大您的盈利潜力，专为积极进取、风险承受能力强的投资者而设计。',
'No ': '没有',
'No Data Found': '未找到数据',
'Next': '下一个',
'PNL': '损益',
'Perpetual SIP Information': '永久SIP信息',
'Perpetual SIP': '永久SIP',
'Pick Perpetual SIP': '选择永久 SIP',
'Play the long game with our Crypto Holding Investment Package, designed for those who believe in the enduring value of virtual currencies.': '使用我们的加密持有投资套餐进行长期游戏，该套餐专为那些相信虚拟货币持久价值的人而设计。',
'Portfolio value': '投资组合价值',
'Previous Week': '上周',
'Promoter': '营销',
'Profile Settings': '配置文件设置',
'Recent Activity': '近期活动',
'Research': '研究',
'SIP History': '系统化投资计划(SIP)历史',
'Scan qrcode or copy bellow code': '扫描二维码或复制以下代码',
'Session Timeout': '登录超时',
'Take your investing to the Next level. Let’s Start Today!': '让您的投资更有利。让我们从今天开始吧！',
'Technology': '科技',
'The - is required': '#NAME?',
'The code is required': '码为必填项',
'Trading': '交易',
'Unleash immediate profits with our Crypto Spot Trading Investment Packages, harnessing real-time cryptocurrency market trends.': '通过我们的加密货币现货交易投资套餐，利用实时加密货币市场趋势，释放即时利润。',
'User Status': '用户状态',
'Username': '用户名',
'Wallet': '钱包',
'We are market makers for top protocols on the most popular blockchains. We enhance market efficiency by identifying mispricings and arbitraging them.': '我们是最流行区块链上顶级协议的做市商，并通过识别错误定价并套利来提高市场效率。',
'We built our own in-house trading system to trade seamlessly on different centralized and decentralized venues 24x7 all year round.': '我们建立了自己的内部交易系统，全年全天候在不同的集中式和分散式场所进行无缝交易。',
'We explore new ideas and apply rigorous research to gain new perspectives. Along the way, we also turn them into handsome profits.': '我们一直在不断的探索新的方法，并应用严谨的研究来获得新的视角。同时，也把它们变成了可观的利润。',
'We live, sleep and breathe DeFi': '我们活、睡和呼吸只为DeFi',
'Welcome': '欢迎',
'What we do': '关于我们的专项',
'Who are we': '关于我们',
'Withdraw Amount': '取款金额',
'Withdraw Type': '取款方式',
'Address': '地址',
'Enter the world of digital wealth. Smart contract and control!': '进入数字财富的世界。智能合约和控制！',
'Change Password': '修改密码',
'City': '城市',
'FROM': '来自',
'From': '来自',
'Perpetual SIP by Category': '按类别划分的永久SIP',
'Promoter Earnings': '推广收入',
'Subscriptions': '',
'Perpetual SIP by Tier': '按等级划分的永久SIP',
'Close': '关闭',
'Tier': '等级',
'Tier 1': '等级一',
'Tier 2': '等级二',
'Tier 3': '等级三',
'Tier 4': '等级四',
'Tier 5': '等级五',
'Tier 6': '等级六',
'Tier 7': '等级七',
'Tier 8': '等级八',
'Tier 9': '等级九',
'Confirm Password': '确认新密码',
'Copy': '复制',
'Country': '国家',
'Dark': '深色',
'DARK': '深色',
'dashboard': '控制板',
'Dashboard': '控制板',
'Date': '日期',
'Deposit': '存',
'Edit Profile': '编辑账户',
'Enter 6 digit auth code': '输入 6 位授权码',
'Enter auth password': '输入身份验证密码',
'Enter your password here': '在此输入您的密码',
'Future': '期货',
'Future Trading': '期货交易',
'Here': '填',
'Light': '浅色',
'LIGHT': '浅色',
'Login': '登录',
'Logout': '登出',
'Name': '名字',
'New password': '新密码',
'Old password': '旧密码',
'OTP (Google Auth)': '谷歌身份验证',
'Perpetual systematic investment plan (SIP)': '永续系统投资计划(SIP)',
'PNL': '损益',
'Phone': '电话号码',
'Previous': '上一个',
'Profile': '账号',
'Profile settings': '配置文件设置',
'Register': '注册',
'Report': '举报 ',
'Sign out': '登出',
'Spot': '现货',
'spot': '现货',
'Spot Trading': '现货交易',
'Start Holding': '开始持有交易',
'Start Investing': '开始投资',
'Start Trading': '开始交易',
'Start Trading (Future)': '开始交易期货',
'Start Trading (Spot)': '开始交易现货',
'Send via crypto network': '通过加密网络发送',
'Send via email': '通过电子邮件发送',
'Support': '客服',
'Success': '成功',
'Theme': '主题',
'This month': '本月',
'this month': '本月',
'Transfer': '转账',
'tx type': '交易类型',
'TX Type': '交易类型',
'Update': '更新',
'username': '用户名',
'Verify ': '验证',
'verify to continue': '验证以继续',
'Withdraw': '取',
'Withdraw Information': '取款信息',
'Wallets': '钱包',
'write your code here': '在这里编写代码',
'Yes': '有',
'zip code': '邮政编码',

'AI Spot trading tools': '现货交易工具',
'AI Future trading tools': '期货交易工具',
'Multiple pairs trading' : '多对交易',
'Automated rebalancing': '自动调仓',
'Smart capital cover': '智能资金覆盖',
'Traditional and Roth IRAs': '传统和罗斯个人退休账户',
'Weekly PNL Distribution': '每周盈亏分配',
'Capital redemption - 7 Days': '资本赎回 - 7 天',

'Next Gemstone Strategy': '下一步宝石策略',
"Mid Cap's Strategy": '中盘股的策略',
'Early bird investment': '早鸟投资',
"DeFi's investment": 'DeFi的投资',
"Traditional and Roth Individual retirement accounts": '传统和罗斯个人退休账户',
"Quarterly PNL Distribution": '季度盈亏分配',
"Capital redemption - 90 Days": '资本赎回 - 90天',

"Lower counterparty risk": '降低交易对手风险',
"Segregate and protect assets": '资产隔离保护',
"Managed cold wallet": '托管冷钱包',
"Smart baskets & rebalancing": '智能篮筐和再平衡',
"Fidelity growth Solutions": '保真增长解决方案',
"Yearly PNL Distribution": '年度损益分配',
"Capital redemption - 365 Days": '资本赎回 - 365天',

"Transaction ID": '交易编号',
"Created at": '创建于',
"Dispatch Approximately": '赎回资本',
"No Data Found": '未找到数据',
"Status": '状态',
"Use Blockchain: Because your transaction history Matters.": '使用区块链：因为你的交易历史很重要。',
"Investment today is a source of income tomorrow.": '今天的投资是明天的收入来源。',
"Statistics": '统计信息',
"Current Year": '本年度',
"Last Year": '上年度',
"All time": '总时度',
"Total Investment": '总投资',
"Total Profit": '利润总额',
"Total Withdraw": '取款总额',
"Capital Redeemed": '已赎回资本',
"TITLE": '名称',
"Title": '名称',
"CATEGORY": '类型',
"Category": '类型',
"TXID": '交易编号',
"TxID": '交易编号',
"ACTION": '行动',
"Action": '行动',
"Wallet History": '钱包记录',
"You are the lifeblood of the business. You won't lose hope, We promise.": '你是企业的命脉。你不会失去希望，我们保证。',
"Earnings": '收入',
"Earning": '收入',
"SIP Activation": 'SIP激活',
"SIP Activation (Community)": 'SIP激活(社区)',
"Community": '社区',
"Community (PNL)": '社区(损益)',
"Community Size": '社区规模',
"capital return - income on promotion": '推广收入',
"Capital Return": '推广收入',
"Recent Transactions": '最近交易',
"Profit": '利润',

"Monday": '周一',
"Tuesday": '周二',
"Wednesday": '周三',
"Thursday": '周四',
"Friday": '周五',
"Saturday": '周六',
"Sunday": '周日',

"January": '一月',
"February": '二月',
"March": '三月',
"April": '四月',
"May": '五月',
"June": '六月',
"July": '七月',
"August": '八月',
"September": '九月',
"October": '十月',
"November": '11月',
"December": '12月',

"Jan": '一月',
"Feb": '二月',
"Mar": '三月',
"Apr": '四月',
"May": '五月',
"Jun": '六月',
"Jul": '七月',
"Aug": '八月',
"Sep": '九月',
"Oct": '十月',
"Nov": '11月',
"Dec": '12月',

"Support Ticket": '客服票证',
"Others": '其它',
"Subject": '主题',
"message here": '在此处输入',
"New world transactions, Powered by Blockchain.": '区块链推动的新世界交易。',
"TRANSACTION ID": '交易编号',
"Transaction id": '交易编号',
"Transaction Id": '交易编号',
"Submit": '提交',
"CREATED AT": '创建于',
"Topic": '题目',

"English": '英语', 
"German": '德语', 
"Spanish": '西班牙语', 
"French": '法语', 
"Chinese": '中文',  
"Arabic": '阿拉伯语', 
"Russian": '俄语', 

"Hurray! There are no archived chats!": '太好了！没有存档的票证！', 
"Ticket List": '票证列表', 
"Create Ticket": '创建票证', 
"Ticket History": '票证记录', 
"PROMOTER LONG": '长仓推广利益', 
"Promoter Long": '长仓推广利益', 
"PROMOTER SHORT": '短仓推广利益', 
"Promoter Short": '短仓推广利益', 
"PNL LONG": '长仓损益', 
"PNL Long": '长仓损益', 
"PNL SHORT": '短仓损益', 
"PNL Short": '短仓损益', 

};
  
  export default cnJSON;
  
