import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SuspenseLoader from "src/components/SuspenseLoader";
import DashboardCrypto from "src/content/dashboards/Crypto";
import ChangePassword from "src/content/pages/profile-settings/change-password";
import EditProfileSettings from "src/content/pages/profile-settings/edit-profile";
import GoogleAuth from "src/content/pages/profile-settings/google-auth";
import ProfileSettings from "src/content/pages/profile-settings/profile";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const Mailbox = Loader(lazy(() => import("src/content/users/Messenger")));
const WithdrawalHistory = Loader(
    lazy(() => import("src/content/users/withdrawal-history"))
);
const Wallet = Loader(lazy(() => import("src/content/users/wallet")));
const Promoters = Loader(lazy(() => import("src/content/users/promoters")));
const Packages = Loader(lazy(() => import("src/content/users/packages")));
const DepositHistory = Loader(
    lazy(() => import("src/content/users/deposit-history"))
);
const BuyPackage = Loader(lazy(() => import("src/content/users/buy-package")));
const AddTicket = Loader(lazy(() => import("src/content/users/add-ticket")));
const DaoProposal = Loader(
    lazy(() => import("src/content/users/dao-proposal"))
);
const InvestmentPool = Loader(
    lazy(() => import("src/content/users/investment-pool"))
);
const InvestmentPoolList = Loader(
    lazy(() => import("src/content/users/investment-pool-list"))
);
const InvestmentProposalDetails = Loader(
    lazy(() => import("src/content/users/investment-proposal-details"))
);

const ReopenedProposal = Loader(
    lazy(() => import("src/content/users/reopened-proposal"))
);
const DaoSipDetails = Loader(
    lazy(() => import("src/content/users/dao-proposal-list"))
);
const DaoReopenProposalList = Loader(
    lazy(() => import("src/content/users/dao-reopen-proposal-list"))
);
const CreateProposal = Loader(
    lazy(() => import("src/content/users/create-proposal"))
);
const DaoSipDetailsAbout = Loader(
    lazy(() => import("src/content/users/dao-about"))
);
const DaoSipReopenDetailsAbout = Loader(
    lazy(() => import("src/content/users/dao-reopen-about"))
);
const EditProposal = Loader(
    lazy(() => import("src/content/users/edit-proposal"))
);
const InvestmentDetailsTreasury = Loader(
    lazy(() => import("src/content/users/investment-treasury"))
);
const DaoProposalDetails = Loader(
    lazy(() => import("src/content/users/dao-proposal-details"))
);
const InvestmentDetailsAbout = Loader(
    lazy(() => import("src/content/users/investment-pool-about"))
);

const MyDaoProposal = Loader(
    lazy(() => import("src/content/users/my-dao-proposal"))
);
const MyReopenProposal = Loader(
    lazy(() => import("src/content/users/my-reopen-proposal"))
);
const MyInvestmentPool = Loader(
    lazy(() => import("src/content/users/my-investment-pool"))
);

const usersRoutes = [
    {
        path: "",
        element: <Navigate to="dashboard" replace />,
    },
    {
        path: "dashboard",
        element: <DashboardCrypto />,
    },
    {
        path: "perpetual-sip-history",
        element: <Packages />,
    },
    {
        path: "perpetual-sip",
        element: <BuyPackage />,
    },
    {
        path: "deposit-history",
        element: <DepositHistory />,
    },
    {
        path: "withdrawal-history",
        element: <WithdrawalHistory />,
    },
    {
        path: "profile-settings/profile",
        element: <ProfileSettings />,
    },
    {
        path: "profile-settings/edit-profile",
        element: <EditProfileSettings />,
    },
    {
        path: "profile-settings/google-auth",
        element: <GoogleAuth />,
    },
    {
        path: "profile-settings/change-password",
        element: <ChangePassword />,
    },
    {
        path: "promoters",
        element: <Promoters />,
    },
    {
        path: "wallet",
        element: <Wallet />,
    },
    {
        path: "support",
        element: <Mailbox />,
    },
    {
        path: "dao-proposal",
        element: <DaoProposal />,
    },
    {
        path: "my-dao-proposal",
        element: <MyDaoProposal />,
    },
    {
        path: "my-reopen-proposal",
        element: <MyReopenProposal />,
    },
    {
        path: "investment-pool",
        element: <InvestmentPool />,
    },
    {
        path: "reopened-proposal",
        element: <ReopenedProposal />,
    },
    {
        path: "dao-proposal-list/:slug",
        element: <DaoSipDetails />,
    },
    {
        path: "dao-reopen-proposal-list/:slug",
        element: <DaoReopenProposalList />,
    },
    {
        path: "dao-reopen-proposal-list/:slug",
        element: <DaoReopenProposalList />,
    },
    {
        path: "dao-reopen-proposal-list/:slug",
        element: <DaoReopenProposalList />,
    },
    {
        path: "investment-pool-list/:slug",
        element: <InvestmentPoolList />,
    },
    {
        path: "dao-proposal-list/:slug/about",
        element: <DaoSipDetailsAbout />,
    },
    {
        path: "dao-reopen-proposal-list/:slug/about",
        element: <DaoSipReopenDetailsAbout />,
    },
    {
        path: "investment-pool-list/:slug/treasury",
        element: <InvestmentDetailsTreasury />,
    },
    {
        path: "investment-pool-list/:slug/about",
        element: <InvestmentDetailsAbout />,
    },
    {
        path: "my-investment-pool",
        element: <MyInvestmentPool />,
    },
    {
        path: "dao-proposal-details/:slug",
        element: <DaoProposalDetails />,
    },
    {
        path: "investment-proposal-details/:slug",
        element: <InvestmentProposalDetails />,
    },

    {
        path: "create-proposal/:slug",
        element: <CreateProposal />,
    },
    {
        path: "edit-proposal/:slug",
        element: <EditProposal />,
    },
    {
        path: "support/create-ticket",
        element: <AddTicket />,
    },
];

export default usersRoutes;
