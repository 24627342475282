import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Select } from "formik-mui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { fetchDataWithToken, userPrivateRequest } from "src/config/axios.config";
import { errorHandler } from "src/utils/errorHandler";
import Swal from "sweetalert2";
import * as Yup from "yup";

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    margin: ${theme.spacing(2, 0, 1, -0.5)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    height: ${theme.spacing(5.5)};
    width: ${theme.spacing(5.5)};
    background: ${
      theme.palette.mode === "dark"
        ? theme.colors.alpha.trueWhite[30]
        : alpha(theme.colors.alpha.black[100], 0.07)
    };
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(4.5)};
      width: ${theme.spacing(4.5)};
    }
`
);
const BoxActions = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]}
    `
);
function Wallets(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const validationSchema = Yup.object().shape({
    deposit_withdrawal: Yup.number().required(
      t("Deposit/Withdraw wallet is required")
    ),
    pnl_affiliate: Yup.number().required(t("PNL/Promoter wallet is required")),
  });
  const initialValues = {
    pnl_affiliate: "",
    deposit_withdrawal: "",
  };
  const confirm_transfer = useMutation(async (payload) => {
    try {
      const res = await userPrivateRequest.post(`user/transfer`, payload);

      return res.data;
    } catch (error) {
      errorHandler(error);
    }
  });
  const handleSubmit = (values, actions) => {
    const data = {
      sender: values.pnl_affiliate,
      receiver: values.deposit_withdrawal,
    };
    confirm_transfer
      .mutateAsync(data)
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: data?.message ?? "Successfully update",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
        fetchDataWithToken("user/dashboard", props.setData);

        setOpen(false);
        actions.resetForm({});
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.message ?? "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      });
  };

  const sumPNL = (short, long) =>{
    let pnl = short + long;
    return pnl.toFixed(2);
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pb: 3,
        }}
      >
        <Typography variant="h3">{t("Wallets")}</Typography>
        <Button size="small" variant="outlined" onClick={handleOpen}>
          {t("Transfer")}
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <CardContent>
              <div className="text-avatar">
                <AvatarWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.5em"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M.3 166.9L0 68C0 57.7 9.5 50.1 19.5 52.3l35.6 7.9c10.6 2.3 19.2 9.9 23 20L96 128l127.3 37.6L181.8 20.4C178.9 10.2 186.6 0 197.2 0h40.1c11.6 0 22.2 6.2 27.9 16.3l109 193.8 107.2 31.7c15.9 4.7 30.8 12.5 43.7 22.8l34.4 27.6c24 19.2 18.1 57.3-10.7 68.2c-41.2 15.6-86.2 18.1-128.8 7L121.7 289.8c-11.1-2.9-21.2-8.7-29.3-16.9L9.5 189.4c-5.9-6-9.3-14-9.3-22.5zM32 448H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32zm96-80a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm128-16a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                      fill={
                        localStorage.getItem("appTheme") ==
                          "GreenFieldsTheme" ||
                        localStorage.getItem("appTheme") ==
                          "NebulaFighterTheme" ||
                        localStorage.getItem("appTheme") == "DarkSpacesTheme"
                          ? "#fff"
                          : "rgba(34, 51, 84, 0.5)"
                      }
                    />
                  </svg>
                </AvatarWrapper>
              </div>
              <Typography variant="h5" noWrap>
              {t("Deposit")}
              </Typography>
              {/* <Typography variant="subtitle1" noWrap>
                BTC
              </Typography> */}
              <Box
                sx={{
                  pt: 1,
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  ${props.wallets?.deposit_bal?.toFixed(2)}
                </Typography>
                {/* <Typography variant="subtitle2" noWrap>
                  1.25843 BTC
                </Typography> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <CardContent>
              <div className="text-avatar">
                <AvatarWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.5em"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M381 114.9L186.1 41.8c-16.7-6.2-35.2-5.3-51.1 2.7L89.1 67.4C78 73 77.2 88.5 87.6 95.2l146.9 94.5L136 240 77.8 214.1c-8.7-3.9-18.8-3.7-27.3 .6L18.3 230.8c-9.3 4.7-11.8 16.8-5 24.7l73.1 85.3c6.1 7.1 15 11.2 24.3 11.2H248.4c5 0 9.9-1.2 14.3-3.4L535.6 212.2c46.5-23.3 82.5-63.3 100.8-112C645.9 75 627.2 48 600.2 48H542.8c-20.2 0-40.2 4.8-58.2 14L381 114.9zM0 480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"
                      fill={
                        localStorage.getItem("appTheme") ==
                          "GreenFieldsTheme" ||
                        localStorage.getItem("appTheme") ==
                          "NebulaFighterTheme" ||
                        localStorage.getItem("appTheme") == "DarkSpacesTheme"
                          ? "#fff"
                          : "rgba(34, 51, 84, 0.5)"
                      }
                    />
                  </svg>
                </AvatarWrapper>
              </div>
              <Typography variant="h5" noWrap>
              {t("Withdraw")}
              </Typography>
              {/* <Typography variant="subtitle1" noWrap>
                XRP
              </Typography> */}
              <Box
                sx={{
                  pt: 1,
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  ${props.wallets?.withdrawal_bal?.toFixed(2)}
                </Typography>
                {/* <Typography variant="subtitle2" noWrap>
                  5,783 XRP
                </Typography> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <CardContent>
              <div className="text-avatar">
                <AvatarWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.5em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z"
                      fill={
                        localStorage.getItem("appTheme") ==
                          "GreenFieldsTheme" ||
                        localStorage.getItem("appTheme") ==
                          "NebulaFighterTheme" ||
                        localStorage.getItem("appTheme") == "DarkSpacesTheme"
                          ? "#fff"
                          : "rgba(34, 51, 84, 0.5)"
                      }
                    />
                  </svg>
                </AvatarWrapper>
              </div>
              <Typography variant="h5" noWrap>
              {t("PNL")}
              </Typography>
              <Box
                sx={{
                  pt: 1,
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  ${sumPNL(props.wallets?.pnl_short, props.wallets?.pnl_long)}
                </Typography>
                {/* <Typography variant="subtitle2" noWrap>
                  34,985 ADA
                </Typography> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <CardContent>
              <div className="text-avatar">
                <AvatarWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.5em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"
                      fill={
                        localStorage.getItem("appTheme") ==
                          "GreenFieldsTheme" ||
                        localStorage.getItem("appTheme") ==
                          "NebulaFighterTheme" ||
                        localStorage.getItem("appTheme") == "DarkSpacesTheme"
                          ? "#fff"
                          : "rgba(34, 51, 84, 0.5)"
                      }
                    />
                  </svg>
                </AvatarWrapper>
              </div>
              <Typography variant="h5" noWrap>
              {t("Promoter")}
              </Typography>
              <Box
                sx={{
                  pt: 1,
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  ${props.wallets?.affiliate_short?.toFixed(2)}
                </Typography>
                {/* <Typography variant="subtitle2" noWrap>
                  34,985 ADA
                </Typography> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("Transfer")}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 3,
          }}
        >
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {() => (
              <Form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} className="w-100">
                    <Field
                      fullWidth
                      name="pnl_affiliate"
                      component={Select}
                      label={t("From")}
                    >
                      <MenuItem value={3}>{t("PNL Wallet")}</MenuItem>
                      <MenuItem value={4}>{t("Promoter Wallet")}</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="w-100">
                    <Field
                      fullWidth
                      name="deposit_withdrawal"
                      component={Select}
                      label={t("To")}
                    >
                      <MenuItem value={1}>{t("Deposit Wallet")}</MenuItem>
                      <MenuItem value={2}>{t("Withdraw Wallet")}</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <BoxActions
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      className="mobile-action-btns"
                      sx={{
                        background: "none",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => setOpen(false)}
                        fullWidth
                        sx={{
                          mr: 1,
                        }}
                      >
                        {t("Close")}
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                          ml: 1,
                        }}
                      >
                        {t("Confirm Transfer")}
                      </Button>
                    </BoxActions>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Wallets;
