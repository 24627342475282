import clsx from "clsx";
import { useContext, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";

import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import {
  Badge,
  Box,
  IconButton,
  ListItem,
  Popover,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Scrollbar from "src/components/Scrollbar";

const IndicatorWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  left: 50%;
  margin-left: -9px;
  top: -${theme.spacing(0.3)};
  width: 18px;
  height: 18px;

  .MuiSvgIcon-root {
    width: 100%;
    height: auto;
  }
`
);

const PopoverWrapper = styled(Popover)(
  ({ theme }) => `
  .MuiList-root {
    min-width: 240px;
    padding: ${theme.spacing(2)} !important;

    .MuiListItem-root {
      padding: 2px 0 !important;

      .MuiIconButton-root {
        width: 100% !important;
        height: auto !important;
        justify-content: flex-start !important;
        font-weight: bold !important;
        background: transparent !important;
        color: ${theme.colors.alpha.black[70]} !important;
        padding: ${theme.spacing(1, 2)} !important;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(2)};
          top: 19px;

          .MuiBadge-badge {
            background: ${theme.colors.primary.main};
            color: ${theme.palette.primary.contrastText};
          }
        }

        .name-wrapper {
          display: block !important;
        }

        &.active,
        &:hover {
          background: ${theme.colors.alpha.black[10]} !important;
          color: ${theme.colors.alpha.black[100]} !important;
        }
      }
    }  
  }
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.black[100],
    color: theme.palette.getContrastText(theme.colors.alpha.black[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.black[100],
  },
}));

const NavigationMenuItem = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <TooltipWrapper
          title={t(name)}
          disableInteractive
          placement="top"
          arrow
        >
          <IconButton
            disableRipple
            className={clsx({ active: open })}
            onClick={handleClick}
          >
            {Icon && Icon}
            {badge === "" ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            )}

            <IndicatorWrapper>
              {open ? (
                <KeyboardArrowDownTwoToneIcon />
              ) : (
                <KeyboardArrowUpTwoToneIcon />
              )}
            </IndicatorWrapper>
          </IconButton>
        </TooltipWrapper>
        <PopoverWrapper
          classes={{ root: "child-popover" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          open={open}
        >
          <Box
            sx={{
              width: 260,
              height: 290,
            }}
          >
            <Scrollbar>{children}</Scrollbar>
          </Box>
        </PopoverWrapper>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <Tooltip arrow title={name}>
        <IconButton
          disableRipple
          component={RouterLink}
          onClick={closeSidebar}
          to={link}
        >
          {Icon && Icon}

          <span className="name-wrapper">{name}</span>
          {badge === "" ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};

NavigationMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

NavigationMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default NavigationMenuItem;
