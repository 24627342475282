import { Box,Button, Card, Grid, Container, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "src/components/LogoSign";
import {Field, Formik, Form } from "formik";
import * as Yup from "yup";
import useAuth from "src/hooks/useAuth";
import Swal from "sweetalert2";
import { privateRequest } from "src/config/axios.config";
import { TextField } from "formik-mui";
import { useNavigate } from "react-router";
import Cookies from 'universal-cookie';
const CryptoJS = require('crypto-js');

const BoxActions = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]}
    `
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

const encryptText = (text, key) => {
  const encrypted = CryptoJS.AES.encrypt(text, key);
  return encrypted.toString();
};

const setCookies = (key, value) => {
  const cookies = new Cookies();
  const encryptedText = encryptText(value, process.env.COOKIE_SECRET_KEY || "");
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + ((process.env.COOKIE_EXPIRES_IN || 60) * 60 * 1000));
  cookies.set(key, encryptedText, {expires: expirationDate});
};

function G2FALogin() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialValues = {
    code: "",
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required(t("The code is required"))
      .matches(/^(?:[1-9]\d*|\d)$/, "The code must be number")
      .max(6, "The code maximum 6")
      .min(6, "The code minimum 6"),
  });

  const handleSubmit = async (values) => {
    const response = await privateRequest.post("admin/gauth-verify", {
      code: values.code,
      remove: 0,
      google2fa_secret: user?.google2fa_secret,
    });
    
    if (response.data.success === false) {
      Swal.fire({
        icon: "error",
        title: response.data?.message,
        showConfirmButton: false,
        timer: 3000,
        customClass:
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme"),
      });
      return;
    }
    setCookies('g2f_verified', user?.google2fa_secret)
    navigate("/admin/dashboard");
  };
  
  return (
    <>
      <Helmet>
        <title>Verify - Google Auth</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Logo />
            </Box>
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3,
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t("Verify Your Account")}
                </Typography>
              </Box>
              {/* <LoginWith2FAForm /> */}
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                initialValues={initialValues}
              >
                {({ values }) => (
                  <Form autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <p className="m-0">
                          Open your Google Authenticator app, and enter the 6-digit code from the app into the
                          input field
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name="code"
                          component={TextField}
                          label={t("Enter 6 digit auth code")}
                          placeholder={t("Write your code here...")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BoxActions
                          display="flex"
                          alignItems="center"
                          justifyContent={'center'}
                        >
                          <Grid item xs={6}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              fullWidth
                            >
                              {t("Verify")}
                            </Button>
                          </Grid>
                        </BoxActions>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default G2FALogin;
