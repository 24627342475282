import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockClockIcon from "@mui/icons-material/LockClock";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { countries } from "../../../utils/countries";
import PasswordIcon from "@mui/icons-material/Password";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  fetchDataWithToken,
  userPrivateRequest,
} from "src/config/axios.config";
import { errorHandler } from "src/utils/errorHandler";
import * as Yup from "yup";
import { value } from "dom7";

function EditProfileSettings() {
  const [getData, setData] = useState(null);
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("The name field is required")),
    phone: Yup.string().required(t("The phone field is required")),
    address: Yup.string().required(t("The address field is required")),
    city: Yup.string().required(t("The city field is required")),
  });
  const initialValues = {
    name: getData?.user_info?.name ?? "",
    telegram_username: getData?.user_info?.telegram_username ?? "",
    phone: getData?.user_details?.phone ?? "",
    address: getData?.user_details?.address ?? "",
    address_optinal: getData?.user_details?.address_optinal ?? "",
    city: getData?.user_details?.city ?? "",
    zipcode: getData?.user_details?.zipcode ?? "",
    country: getData?.user_details?.country ?? "",
  };
  useEffect(() => {
    fetchDataWithToken("user/accounts", setData);
  }, []);

  const editProfile = useMutation(async (payload) => {
    console.log(payload, "payload");
    try {
      const res = await userPrivateRequest.post(
        `user/accounts-update`,
        payload
      );
      return res.data;
    } catch (error) {
      errorHandler(error);
    }
  });
  const handleSubmit = (values, actions) => {
    editProfile
      .mutateAsync(values)
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: data?.message ?? "Successfully update profile",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.message ?? "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      });
  };
  return (
    <Grid
      container
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
      columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
      className="responsive-container"
      justifyContent="center"
    >
      <Grid item lg={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            pt: 3,
            mb: 3,
          }}
        >
          <Typography variant="h3">{t("Edit Profile")}</Typography>
        </Box>
        <Card
          sx={{
            px: 1,
            mb: 3,
          }}
        >
          <CardContent>
            <ul
              className={`profile-menu ${
                (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                  "GreenFieldsTheme") ||
                (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                  "NebulaFighterTheme") ||
                (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                  "DarkSpacesTheme") ||
                (localStorage.getItem("appTheme") == "PureLightTheme" &&
                  "PureLightTheme") ||
                (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                  "GreyGooseTheme") ||
                (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                  "PurpleFlowTheme")
              }`}
            >
              <li>
                <NavLink to="/profile-settings/profile">
                  <AccountCircleIcon /> Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile-settings/edit-profile">
                  <HistoryEduIcon />
                  Edit Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile-settings/google-auth">
                  <LockClockIcon /> Google Auth
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile-settings/change-password">
                  <PasswordIcon /> Change Password
                </NavLink>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card
          sx={{
            position: "relative",
            p: 3,
            mb: 4,
          }}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      autoFocus
                      helperText={touched.name && errors.name}
                      label={t("Name")}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      placeholder="Name here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(
                        touched.telegram_username && errors.telegram_username
                      )}
                      fullWidth
                      helperText={
                        touched.telegram_username && errors.telegram_username
                      }
                      label={t("Username")}
                      name="telegram_username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telegram_username}
                      variant="outlined"
                      placeholder="Telegram Username here"
                      readOnly
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label={t("Phone")}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                      placeholder="Phone here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(
                        touched.address_optinal && errors.address_optinal
                      )}
                      fullWidth
                      helperText={
                        touched.address_optinal && errors.address_optinal
                      }
                      label={t("Address Optinal")}
                      name="address_optinal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address_optinal}
                      variant="outlined"
                      placeholder="Address here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label={t("Address")}
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                      placeholder="Address here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      label={t("Country")}
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      variant="outlined"
                      placeholder="Country here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.zipcode && errors.zipcode)}
                      fullWidth
                      helperText={touched.zipcode && errors.zipcode}
                      label={t("Zip Code")}
                      name="zipcode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.zipcode}
                      variant="outlined"
                      placeholder="Zip Code here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label={t("City")}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                      placeholder="City here"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setFieldValue("country", newValue ? newValue.code : ""); // Handle case when clearing the selection
                    }}
                    value={countries?.find((item) => item.code === values.country) || null}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "country",
                        }}
                      />
                    )}
                  />

                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      {t("Update")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EditProfileSettings;
