import { Box, Grid, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import LandingHeader from "src/components/landing/header";

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
  `
);
const LandingPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Bitgrow</title>
      </Helmet>
      <MainContent>
        <LandingHeader />
        <main className="main-container">
          <Grid className="circle-wrap">
            <img
              className="circle___29_GF"
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTA3NHB4IiBoZWlnaHQ9IjEwNzRweCIgdmlld0JveD0iMCAwIDEwNzQgMTA3NCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPkNpcmNsZWVtcHR5PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPGNpcmNsZSBpZD0icGF0aC0xIiBjeD0iNjU1IiBjeT0iMTUxMCIgcj0iNTM0Ij48L2NpcmNsZT4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJEZXNrdG9wIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMS4wX0hvbWUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTguMDAwMDAwLCAtOTczLjAwMDAwMCkiPgogICAgICAgICAgICA8bWFzayBpZD0ibWFzay0yIiBmaWxsPSJ3aGl0ZSI+CiAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgICAgICA8L21hc2s+CiAgICAgICAgICAgIDx1c2UgaWQ9IkNpcmNsZSIgc3Ryb2tlPSIjODZGOUIzIiBzdHJva2Utd2lkdGg9IjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWRhc2hhcnJheT0iMCwzMyIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
              alt="dot circle"
            />
            <img
              className="circle___29_GF coins___B8b8i rotating___3RJO7"
              src="/static/images/circel.svg"
              alt="coin circle"
            />
          </Grid>
          <Grid className="weare-wrapper">
            <Grid className="container" container spacing={4}>
              <Grid item xs={12} md={7}>
                <p className="subtitle">
                  A Digital-Native <span>asset manager</span> for a
                  digital-driven world
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="whoare-wrapper">
            <Grid className="container" container spacing={4}>
              <Grid item xs={12} md={6}>
                <h2 className="title">Who are we</h2>
                <p>
                  Established in 2017, Bitgrow is a trading firm specializing in
                  cryptocurrencies with an AUM of USD 10 million.
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="whatare-wrapper">
            <Grid className="container" container spacing={4}>
              <Grid item lg={4} md={6} xs={12}>
                <Grid className="title-wrap">
                  <h2 className="title">What we do</h2>
                  <p>We live, sleep and breathe DeFi (dee-fye).</p>
                </Grid>
              </Grid>
              <Grid item lg={7} md={6} xs={12}>
                <ul className="list-wrap">
                  <li>
                    <h4>Trading</h4>
                    <p>
                      We are market makers for top protocols on the most popular
                      blockchains. We enhance market efficiency by identifying
                      mispricings and arbitraging them.
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <p>
                      We built our own in-house trading system to trade
                      seamlessly on different centralized and decentralized
                      venues 24x7 all year round.
                    </p>
                  </li>
                  <li>
                    <h4>Research</h4>
                    <p>
                      We explore new ideas and apply rigorous research to gain
                      new perspectives. Along the way, we also turn them into
                      handsome profits.
                    </p>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </main>
        <footer className="footer-area">
          {/* <img className="footer-image" src="/static/images/image.png" /> */}
          <Grid container className="container" justifyContent="center">
            <Grid item xs={12}>
              <p>
                Copyright &copy; 2017-{new Date().getFullYear()} Bitgrow. All Rights
                Reserved.
              </p>
            </Grid>
          </Grid>
        </footer>
      </MainContent>
    </>
  );
};

export default LandingPage;
