import React from "react";
import QRCode from "qrcode.react";
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const QRCodeComponent = ({ data, size = 148 }) => {
  return (
    <StyledPaper elevation={3} className="bg-none">
      <div
        className={`qrcode-wrap ${
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme")
        }`}
      >
        <QRCode value={data} size={size} />
      </div>
      <div className="pt-4">For USDT Deposit Only</div>
    </StyledPaper>
  );
};

export default QRCodeComponent;
