import {
  Avatar,
  Grid,
  Typography,
  alpha,
  lighten,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";
const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${
          theme.palette.mode === "dark"
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${
          theme.palette.mode === "dark"
            ? "0 1px 0 " +
              alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
              ", 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)"
            : "0px 2px 4px -3px " +
              alpha(theme.colors.alpha.black[100], 0.4) +
              ", 0px 5px 16px -4px " +
              alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);
function PageHeader() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item>
        <AvatarPageTitle variant="rounded">
          <svg
            height="1.5em"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill={
                localStorage.getItem("appTheme") == "GreenFieldsTheme" ||
                localStorage.getItem("appTheme") == "NebulaFighterTheme" ||
                localStorage.getItem("appTheme") == "DarkSpacesTheme"
                  ? "#fff"
                  : "rgba(34, 51, 84, 0.5)"
              }
              d="M0 72C0 49.9 17.9 32 40 32H88c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V72zM0 232c0-22.1 17.9-40 40-40H88c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V232zM128 392v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40H88c22.1 0 40 17.9 40 40zM160 72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V72zM288 232v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM160 392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392zM448 72v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM320 232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V232zM448 392v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40z"
            />
          </svg>
        </AvatarPageTitle>
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {t("Welcome")}, {user.name}!
        </Typography>
        <Typography variant="subtitle2">
          {t("Take your investing to the Next level. Let’s Start Today!")}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
