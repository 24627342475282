import PropTypes from "prop-types";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import AppInit from "../AppInit";

const UserAuthenticated = (props) => {
  const { children } = props;
  const auth = useAuth();

  useEffect(() => {
    auth?.userInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!auth.isInitialized) {
    return <AppInit />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

UserAuthenticated.propTypes = {
  children: PropTypes.node,
};

export default UserAuthenticated;
