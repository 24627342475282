import { Box, Card, Container, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "src/components/LogoSign";
import LoginForm from "./LoginForm";
import useAuth from "src/hooks/useAuth";
import Cookies from 'universal-cookie';
import G2FALogin from "src/content/pages/Auth/G2FALogin";
const CryptoJS = require('crypto-js');

const decryptText = (value) => {
  const cookies = new Cookies();
  if (cookies.get("g2f_verified")) {
    const bytes = CryptoJS.AES.decrypt(cookies.get("g2f_verified"), (process.env.COOKIE_EXPIRES_IN || ""));
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (decrypted === value){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
};

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  const { t } = useTranslation();
  const {user} = useAuth();
  const token = localStorage.getItem("adminAccessToken");
  if (token && (user?.g2f_enabled === 0)) {
    return <Navigate to={"/admin/dashboard"} />;
  }
  if (token && (user?.g2f_enabled === 1) && !decryptText(user?.google2fa_secret)) {
    return <G2FALogin />;
  }
  if (token && (user?.g2f_enabled === 1) && decryptText(user?.google2fa_secret)) {
    return <Navigate to={"/admin/dashboard"} />;
  }{
    return (
      <>
        <Helmet>
          <title>Login - Basic</title>
        </Helmet>
        <MainContent>
          <TopWrapper>
            <Container maxWidth="sm">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Logo />
              </Box>
              <Card
                sx={{
                  mt: 3,
                  px: 4,
                  pt: 5,
                  pb: 3,
                }}
              >
                <Box>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 1,
                    }}
                  >
                    {t("Sign in")}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{
                      mb: 3,
                    }}
                  >
                    {t("Fill in the fields below to sign into your account.")}
                  </Typography>
                </Box>
                <LoginForm />
              </Card>
            </Container>
          </TopWrapper>
        </MainContent>
      </>
    );
  }
}

export default LoginBasic;
