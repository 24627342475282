import { Box,Button, Card, Grid, Container, Typography, styled, Dialog } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Logo from "src/components/LogoSign";
import {Field, Formik, Form } from "formik";
import useAuth from "src/hooks/useAuth";
import Swal from "sweetalert2";
import { userPrivateRequest } from "src/config/axios.config";
import { TextField } from "formik-mui";
import { useNavigate } from "react-router";
import React, { useState, useEffect } from 'react';

const CryptoJS = require('crypto-js');

const BoxActions = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]}
    `
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LogoutTimer() {
  const { user, logout, setSession } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialValues = {
    password: "",
  };

  const [idleTime, setIdleTime] = useState(0);
  const [gAuthOpen, setGAuthOpen] = useState(false);

  const resetIdleTime = () => {
    setIdleTime(0);
  };

  const handleMouseMove = () => {
    resetIdleTime();
  };
  
  const handleKeyPress = () => {
    resetIdleTime();
  };

  useEffect(() => {
    const idleInterval = setInterval(() => {
      setIdleTime((prevIdleTime) => prevIdleTime + 1);
    }, 60000); // 1 minute

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('keypress', handleKeyPress);

    return () => {
      clearInterval(idleInterval);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress, handleMouseMove]);

  useEffect(() => {

    if (idleTime > 30) { // 30 minutes
      handleGAuthOpen()
    }
  }, [idleTime]);

  const handleGAuthOpen = async () => {
    setSession(null)
    setGAuthOpen(true)
  };

  const handleDialogClose = async () => {
    setGAuthOpen(false);
    await logout();
    navigate("/login");
  };

  const handleSubmit = async (values) => {
    const response = await userPrivateRequest.post("user/login", {
      email: user?.email,
      password: values.password,
    });
    
    if (response.data.success === false) {
      Swal.fire({
        icon: "error",
        title: response.data?.message,
        showConfirmButton: false,
        timer: 3000,
        customClass:
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme"),
      });
      return;
    }
    const { token } = response.data.data;
    setSession(token);
    setGAuthOpen(false);
  };

  if (gAuthOpen) {
    return (
      <>
        <Helmet>
          <title>Session Timeout</title>
        </Helmet>
        <Dialog
        fullScreen
        open={gAuthOpen}
        >
          <TopWrapper>
            <Container maxWidth="sm">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Logo />
              </Box>
              <Card
                sx={{
                  mt: 3,
                  px: 4,
                  pt: 5,
                  pb: 3,
                }}
              >
                <Box>
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 1,
                    }}
                  >
                    {t("Session Timeout")}
                  </Typography>
                </Box>
                <Formik
                  enableReinitialize
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                >
                  {({ values }) => (
                    <Form autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <p className="m-0">
                            Enter your password to continue with the current session
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="password"
                            type="password"
                            component={TextField}
                            label={t("Enter auth password")}
                            placeholder={t("Enter your password here...")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BoxActions
                            display="flex"
                            alignItems="center"
                            justifyContent={'space-between'}
                          >
                            <Grid item xs={5}>
                              <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                              >
                                {t("Verify to continue")}
                              </Button>
                            </Grid>
                            <Grid item xs={5}>
                              <Button
                                color="primary"
                                type="button"
                                onClick={() => handleDialogClose()}
                                variant="contained"
                                fullWidth
                              >
                                {t("Logout")}
                              </Button>
                            </Grid>
                          </BoxActions>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Container>
          </TopWrapper>
        </Dialog>
      </>
    );
  }else{
    return(
      <></>
    );  
  }
}

export default LogoutTimer;
