import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import Label from "src/components/Label";
import { convertDateTime } from "src/contexts/custom.js";
import { useTranslation } from "react-i18next";

const getStatusLabel = (cryptoOrderStatus) => {
  const map = {
    in: {
      text: "In",
      color: "success",
    },
    out: {
      text: "Out",
      color: "error",
    },
  };

  const { text, color } = map[cryptoOrderStatus];

  return <Label color={color}>{text}</Label>;
};
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const ResponsiveTable = (props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(props?.transactions?.data[0]?.id);
  useEffect(() => {
    setExpanded(props?.transactions?.data[0]?.id);
  }, [props?.transactions?.data]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div
      className={`responsive-table ${
        (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
          "GreenFieldsTheme") ||
        (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
          "NebulaFighterTheme") ||
        (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
          "DarkSpacesTheme") ||
        (localStorage.getItem("appTheme") == "PureLightTheme" &&
          "PureLightTheme") ||
        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
          "GreyGooseTheme") ||
        (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
          "PurpleFlowTheme")
      }`}
    >
      {props?.transactions?.data?.map((item) => (
        <Accordion
          expanded={expanded === item?.id}
          onChange={handleChange(item?.id)}
          className="accordion-wrap"
          key={item?.id}
        >
          <AccordionSummary className="accordion-header">
            <ul className="table-items">
              <li>
                <strong className="label">{t("Description")}</strong>
                <span className="value">{item?.reason}</span>
              </li>
            </ul>
          </AccordionSummary>
          <AccordionDetails className="accordion-body">
            <ul className="table-items">
              <li>
                <strong className="label">{t("Amount")}</strong>
                <span className="value">{item?.amount}</span>
              </li>
              <li>
                <strong className="label">{t("Transaction Type")}</strong>
                <span className="value">{getStatusLabel(item?.type)}</span>
              </li>
              <li>
                <strong className="label">{t("Date")}</strong>
                <span className="value">
                  {convertDateTime(item?.created_at)}
                </span>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default ResponsiveTable;
