import { createContext, useReducer } from "react";
//import axios from 'src/utils/axios';
import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { privateRequest, userPrivateRequest } from "src/config/axios.config";
import { errorHandler } from "src/utils/errorHandler";
import Swal from "sweetalert2";

const initialAuthState = {
  isAuthenticated: false,
  isAdminAuthenticated: false,
  isInitialized: false,
  user: null,
  adminInitialize: null,
  userInitialize: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("role", "user");
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setAdminSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("adminAccessToken", accessToken);
    localStorage.setItem("role", "admin");
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("role");
    delete axios.defaults.headers.common.Authorization;
  }
  setTimeout(function() {
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("role");
    delete axios.defaults.headers.common.Authorization;
  }, (process.env.COOKIE_EXPIRES_IN || 60) * 60 * 1000);
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  ADMIN_INITIALIZE: (state, action) => {
    const { user, isAdminAuthenticated } = action.payload;

    return {
      ...state,
      isAdminAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  ADMIN_LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAdminAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  ADMIN_LOGOUT: (state) => ({
    ...state,
    isAdminAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  adminLogin: () => Promise.resolve(),
  adminLogout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setSession: () => Promise.resolve(),
  setAdminSession: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const adminInitialize = async () => {
    try {
      const accessToken = window.localStorage.getItem("adminAccessToken");

      if (accessToken) {
        setAdminSession(accessToken);

        const response = await privateRequest.get("admin/auth_check");
        const { user } = response.data.data;
        if (response.data.success) {
          dispatch({
            type: "ADMIN_INITIALIZE",
            payload: {
              isAdminAuthenticated: true,
              user,
            },
          });
          return;
        }
      }
      dispatch({
        type: "ADMIN_INITIALIZE",
        payload: {
          isAdminAuthenticated: false,
          user: null,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "ADMIN_INITIALIZE",
        payload: {
          isAdminAuthenticated: false,
          user: null,
        },
      });
    }
  };
  const userInitialize = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      if (accessToken) {
        setSession(accessToken);

        const response = await userPrivateRequest.get("user/auth_check");
        const { user } = response.data.data;
        if (response.data.success) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          return;
        }
      }
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (email, password, id) => {
    try {
      const response = await userPrivateRequest.post("user/login", {
        email,
        password,
        id,
      });
      if (response.data.success === false) {
        Swal.fire({
          icon: "error",
          title: response.data?.message,
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
        return;
      }
      const { token, user } = response.data.data;
      setSession(token);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      if (id) {
        window.open("/dashboard", "_blank");
      } else {
        navigate("/dashboard");
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const adminLogin = async (email, password) => {
    const response = await privateRequest.post("admin/login", {
      email,
      password,
    });
    if (response.data.success === false) {
      Swal.fire({
        icon: "error",
        title: response.data?.message,
        showConfirmButton: false,
        timer: 3000,
        customClass:
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme"),
      });
      return;
    }
    const { token, user } = response.data?.data;
    setAdminSession(token);
    dispatch({
      type: "ADMIN_LOGIN",
      payload: {
        user,
      },
    });

  };

  const adminLogout = async () => {
    setAdminSession(null);
    dispatch({ type: "ADMIN_LOGOUT" });
  };
  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (email, name, password) => {
    const response = await userPrivateRequest.post("account/register", {
      email,
      name,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        adminLogin,
        adminLogout,
        logout,
        register,
        adminInitialize,
        userInitialize,
        setSession,
        setAdminSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
