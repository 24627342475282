import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "";

// common config
axios.defaults.headers.post["Content-Type"] = "application/json";

const publicRequest = axios.create({
    baseURL: apiUrl,
});

const privateRequest = axios.create({
    baseURL: apiUrl,
});

const userPrivateRequest = axios.create({
    baseURL: apiUrl,
});

let token = "";

// Add a request interceptor
privateRequest.interceptors.request.use(
    async (config) => {
        token = localStorage.getItem("adminAccessToken");
        if (token) {
            config.headers.Authorization = "Bearer " + token || "";
        }
        return config;
    },
    (err) => {
        Promise.reject(err);
    }
);
// Add user request interceptor
userPrivateRequest.interceptors.request.use(
    async (config) => {
        token = localStorage.getItem("accessToken");

        if (token) {
            config.headers.Authorization = "Bearer " + token || "";
        }
        return config;
    },
    (err) => {
        Promise.reject(err);
    }
);

// Add user response interceptor
userPrivateRequest.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.error(error?.response);
        if (
            error?.response?.status === 401 ||
            error?.response?.status === "401"
        ) {
            // localStorage.removeItem('token')
        }

        return Promise.reject(error);
    }
);

// Add a response interceptor
privateRequest.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.error(error?.response);
        if (
            error?.response?.status === 401 ||
            error?.response?.status === "401"
        ) {
            // localStorage.removeItem('token')
        }

        return Promise.reject(error);
    }
);

export { privateRequest, publicRequest, userPrivateRequest };

export const fetchDataWithToken = async (url, setData) => {
    try {
        const token = localStorage.getItem("accessToken");
        const headers = {
            Authorization: `Bearer ${token ?? ""}`,
        };

        const response = await axios.get(`${apiUrl}${url}`, {
            headers,
        });
        setData(response.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

export const fetchDataWithAdminToken = async (url, setData) => {
    try {
        const token = localStorage.getItem("adminAccessToken");
        const headers = {
            Authorization: `Bearer ${token ?? ""}`,
        };

        const response = await axios.get(`${apiUrl}${url}`, {
            headers,
        });
        setData(response.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
