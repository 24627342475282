import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SuspenseLoader from "src/components/SuspenseLoader";
import ProfileSettings from "src/content/pages/admin-profile-settings/profile";
import ChangePassword from "src/content/pages/admin-profile-settings/change-password";
import GoogleAuth from "src/content/pages/admin-profile-settings/google-auth";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Expenses = Loader(lazy(() => import("src/content/dashboards/Expenses")));

// custom admin routes
const Packages = Loader(lazy(() => import("src/content/admin/packages")));
const Email = Loader(lazy(() => import("src/content/admin/email")));
const Users = Loader(lazy(() => import("src/content/admin/users")));
const PurchasedPackageHistory = Loader(
  lazy(() => import("src/content/admin/purchased-history"))
);
const ProposalHistory = Loader(
  lazy(() => import("src/content/admin/proposal-history"))
);
const WithdrawalHistory = Loader(
  lazy(() => import("src/content/admin/withdrawal-history"))
);
const PendingWithdrawalHistory = Loader(
  lazy(() => import("src/content/admin/withdrawal-pending-history"))
);
const DepositHistory = Loader(
  lazy(() => import("src/content/admin/deposit-history"))
);
const WalletHistory = Loader(
  lazy(() => import("src/content/admin/wallet-history"))
);
const PNLHistory = Loader(
  lazy(() => import("src/content/admin/pnl-history"))
);
const PoolPNLHistory = Loader(
  lazy(() => import("src/content/admin/pool-pnl-history"))
);
const PoolPNLDistributionHistory = Loader(
  lazy(() => import("src/content/admin/pool-pnl-distribution-history"))
);
const PoolInvestmentHistory = Loader(
  lazy(() => import("src/content/admin/pool-investment-history"))
);
const SpotPNLHistory = Loader(
  lazy(() => import("src/content/admin/spot-pnl-history"))
);
const FuturePNLHistory = Loader(
  lazy(() => import("src/content/admin/future-pnl-history"))
);
const InvestmentPNLHistory = Loader(
  lazy(() => import("src/content/admin/investment-pnl-history"))
);
const HoldingPNLHistory = Loader(
  lazy(() => import("src/content/admin/holding-pnl-history"))
);
const AffiliateHistory = Loader(
  lazy(() => import("src/content/admin/affiliate-history"))
);
const WalletList = Loader(
  lazy(() => import("src/content/admin/wallet-list"))
);
const CommonSetting = Loader(
  lazy(() => import("src/content/admin/common-setting"))
);
const UniSetting = Loader(
  lazy(() => import("src/content/admin/unilevel-setting"))
);
const Mailbox = Loader(lazy(() => import("src/content/admin/Messenger")));
const MailboxSingle = Loader(
  lazy(() => import("src/content/admin/Mailbox/Single"))
);

const AdminLogin = Loader(
  lazy(() => import("src/content/pages/Auth/AdminLogin"))
);
const G2FALogin = Loader(
  lazy(() => import("src/content/pages/Auth/G2FALogin"))
);
const PNLDistributionHistory = Loader(
  lazy(() => import("src/content/admin/pnl-distribution-history"))
);
const SIPRedeemRequestList = Loader(
  lazy(() => import("src/content/admin/sip-redeem-request-list"))
);
const SIPRedeemSuccessList = Loader(
  lazy(() => import("src/content/admin/sip-redeem-success-list"))
);
const WithdrawRequestList = Loader(
  lazy(() => import("src/content/admin/withdrawal-request-list"))
);
const WithdrawSuccessList = Loader(
  lazy(() => import("src/content/admin/withdrawal-success-list"))
);
const UserOverview = Loader(
  lazy(() => import("src/content/admin/user-overview"))
);
const NonDistributedHistory = Loader(
  lazy(() => import("src/content/admin/non-distributed-history"))
);

// fake user section start
const RegisterFakeUser = Loader(
  lazy(() => import("src/content/pages/Auth/RegisterFakeUser"))
);
const FakeUsers = Loader(
  lazy(() => import("src/content/admin/fake-users"))
);
const FakeUserWithdraw = Loader(
  lazy(() => import("src/components/users/FakeUserWithdraw"))
);
const FakeUserDeposit = Loader(
  lazy(() => import("src/components/users/FakeUserDeposit"))
);
const FakeUserPackagePurchase = Loader(
  lazy(() => import("src/content/users/fake_users/FakeUserPackagePurchase"))
);
const InvestmentPool = Loader(
  lazy(() => import("src/content/admin/investment-pool"))
);
const DAOCategory = Loader(
  lazy(() => import("src/content/admin/dao-category"))
);
// fake user section end
const adminsRoutes = [
  {
    path: "",
    element: <Navigate to="dashboard" replace />,
  },
  {
    path: "profile-settings/profile",
    element: <ProfileSettings />,
  },
  {
    path: "profile-settings/google-auth",
    element: <GoogleAuth />,
  },
  {
    path: "profile-settings/change-password",
    element: <ChangePassword />,
  },
  {
    path: "dashboard",
    element: <Expenses />,
  },
  {
    path: "login",
    element: <AdminLogin />,
  },
  {
    path: "ga-login",
    element: <G2FALogin />,
  },{
    path: "pnl-distribution-history",
    element: <PNLDistributionHistory />,
  },
  {
    path: "packages",
    element: <Packages />,
  },
  {
    path: "email",
    element: <Email />,
  },
  {
    path: "users",
    element: <Users />,
  },
  {
    path: "users/:slug/:user_id",
    element: <Users />,
  },
  {
    path: "purchased-package-history",
    element: <PurchasedPackageHistory />,
  },
  {
    path: "withdrawal-history",
    element: <WithdrawalHistory />,
  },
  {
    path: "pending-withdrawal-history",
    element: <PendingWithdrawalHistory />,
  },
  {
    path: "deposit-history",
    element: <DepositHistory />,
  },
  {
    path: "wallet-history",
    element: <WalletHistory />,
  },
  {
    path: "pnl-history",
    element: <PNLHistory />,
  },
  {
    path: "spot-pnl-history",
    element: <SpotPNLHistory />,
  },
  {
    path: "future-pnl-history",
    element: <FuturePNLHistory />,
  },
  {
    path: "investment-pnl-history",
    element: <InvestmentPNLHistory />,
  },
  {
    path: "holding-pnl-history",
    element: <HoldingPNLHistory />,
  },
  {
    path: "affiliate-history",
    element: <AffiliateHistory />,
  },
  {
    path: "wallet-list",
    element: <WalletList />,
  },
  {
    path: "settings",
    element: <CommonSetting />,
  },
  {
    path: "unilevel-settings",
    element: <UniSetting />,
  },
  {
    path: "support",
    element: <Mailbox />,
  },
  {
    path: "sip-redeem-request-list",
    element: <SIPRedeemRequestList />,
  },
  {
    path: "sip-redeem-success-list",
    element: <SIPRedeemSuccessList />,
  },
  {
    path: "withdraw-request-list",
    element: <WithdrawRequestList />,
  },
  {
    path: "withdraw-success-list",
    element: <WithdrawSuccessList />,
  },
  {
    path: "user-overview",
    element: <UserOverview />,
  },
  {
    path: "non-distributed-history",
    element: <NonDistributedHistory />,
  },
  {
    path: "investment-pool",
    element: <InvestmentPool />,
  },
  {
    path: "dao-category",
    element: <DAOCategory />,
  },
  {
    path: "proposal-history",
    element: <ProposalHistory />,
  },
  {
    path: "pool-pnl-history",
    element: <PoolPNLHistory />,
  },
  {

    path: "pool-investment-history",
    element: <PoolInvestmentHistory />,
  },
  {
    path: "pool-pnl-distribution-history",
    element: <PoolPNLDistributionHistory />,
  },

  // fake user section starting
  {
    path: "create-fake-user",
    element: <RegisterFakeUser />,
  },
  {
    path: "fake-users",
    element: <FakeUsers />,
  },
  {
    path: "fake-deposit",
    element: <FakeUserDeposit />,
  },
  {
    path: "fake-withdrawal",
    element: <FakeUserWithdraw />,
  },
  {
    path: "package-purchase",
    element: <FakeUserPackagePurchase />,
  },
  // fake user section end
];

export default adminsRoutes;
