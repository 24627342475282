import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AdminLogin from "src/content/pages/Auth/AdminLogin";
import G2FALogin from "src/content/pages/Auth/G2FALogin";
import useAuth from "src/hooks/useAuth";
import AppInit from "../AppInit";
import Cookies from 'universal-cookie';
const CryptoJS = require('crypto-js');

const decryptText = (value) => {
  const cookies = new Cookies();
  if (cookies.get("g2f_verified")) {
    const bytes = CryptoJS.AES.decrypt(cookies.get("g2f_verified"), process.env.COOKIE_SECRET_KEY || "");
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (decrypted === value){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
};

const AdminAuthenticated = (props) => {
  const { children } = props;
  const auth = useAuth();
  const {user} = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    auth?.adminInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!auth.isInitialized) {
    return <AppInit />;
  }

  if (!auth.isAdminAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <AdminLogin />;
  }
  
  if (auth.isAdminAuthenticated && (user?.g2f_enabled === 1) && !decryptText(user?.google2fa_secret)){
    return <G2FALogin />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AdminAuthenticated.propTypes = {
  children: PropTypes.node,
};

export default AdminAuthenticated;
