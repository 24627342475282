import detectEthereumProvider from "@metamask/detect-provider";
import TrendingDown from "@mui/icons-material/TrendingDown";
import TrendingUp from "@mui/icons-material/TrendingUp";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  alpha,
  styled,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import {
  fetchDataWithToken,
  userPrivateRequest,
} from "src/config/axios.config";
import { errorHandler } from "src/utils/errorHandler";
import Swal from "sweetalert2";
import Web3 from "web3";
import copy from "copy-text-to-clipboard";
import QRCodeComponent from "src/components/QRCode";

const BoxActions = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]}
    `
);

const formatBalance = (rawBalance) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(8);
  return balance;
};

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      box-shadow: ${theme.colors.shadows.success};
`
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.main};
      color: ${theme.palette.error.contrastText};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      box-shadow: ${theme.colors.shadows.error};
`
);
const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    margin: ${theme.spacing(2, 0, 1, -0.5)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    height: ${theme.spacing(5.5)};
    width: ${theme.spacing(5.5)};
    background: ${
      theme.palette.mode === "dark"
        ? theme.colors.alpha.trueWhite[30]
        : alpha(theme.colors.alpha.black[100], 0.07)
    };
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(4.5)};
      width: ${theme.spacing(4.5)};
    }
`
);
const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.8)};
    height: ${theme.spacing(1.8)};
    display: inline-block;
    margin-right: ${theme.spacing(0.8)};
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
);
function AccountBalance(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [getData, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const initialState = { accounts: [] };
  const [wallet, setWallet] = useState(initialState);
  const [inputAmount, setInputAmount] = useState(
    props.data?.depositSettings?.deposit_min_val ?? ""
  );
  //   const [isOpen, setIsOpen] = useState(false);
  const [usdtBal, setUsdtBal] = useState(0);
  //   const [transactionUrl, setTransactionUrl] = useState("");

  const [openDeposit, setOpenDeposit] = useState(false);
  const chartOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    colors: ["#ff9900", "#1c81c2", "#333", "#5c6ac0"],
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val.toFixed(2)}%`;
      },
      style: {
        colors: [theme.colors.alpha.trueWhite[100]],
      },
      background: {
        enabled: true,
        foreColor: theme.colors.alpha.trueWhite[100],
        padding: 8,
        borderRadius: 4,
        borderWidth: 0,
        opacity: 0.3,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[70],
          opacity: 0.5,
        },
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: [t("Spot"), t("Future"), t("Investment"), t("Hold")],
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100],
      },
      show: false,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };
  const chartOptions_default = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#999", "#999", "#999", "#999"],
    dataLabels: {
      enabled: false,
      formatter(val) {
        return `${val}%`;
      },
      style: {
        colors: [theme.colors.alpha.trueWhite[100]],
      },
      background: {
        enabled: true,
        foreColor: theme.colors.alpha.trueWhite[100],
        padding: 8,
        borderRadius: 4,
        borderWidth: 0,
        opacity: 0.3,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[70],
          opacity: 0.5,
        },
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: [t("Spot"), t("Future"), t("Investment"), t("Hold")],
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100],
      },
      show: false,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };
  const chartSeries = [
    props.data?.spot_invest,
    props.data?.future_invest,
    props.data?.presale_invest,
    props.data?.hold_invest,
  ];

  const chartSeries_data = [10, 0, 0, 0];
  //   wallet connect -> metamask

  useEffect(() => {
    fetchDataWithToken("user/wallet-address", setData);
    const refreshAccounts = (accounts) => {
      if (accounts.length > 0) {
        updateWallet(accounts);
      }
    };

    const refreshChain = (chainId) => {
      setWallet((wallet) => ({ ...wallet, chainId }));
      getProvider();
    };

    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true });

      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        refreshAccounts(accounts);
        window.ethereum.on("accountsChanged", refreshAccounts);
        window.ethereum.on("chainChanged", refreshChain);
      }
    };
    // handleFetchCoinInfo();
    getProvider();
    // getSupplyInfo();
    return () => {
      window.ethereum?.removeListener("accountsChanged", refreshAccounts);
      window.ethereum?.removeListener("chainChanged", refreshChain);
    };
  }, []);
  useEffect(() => {
    setInputAmount(props.data?.depositSettings?.deposit_min_val);
  }, [props.data?.depositSettings?.deposit_min_val]);
  const updateWallet = async (accounts) => {
    const balance = formatBalance(
      await window.ethereum.request({
        method: "eth_getBalance",
        params: [accounts[0], "latest"],
      })
    );
    const chainId = await window.ethereum.request({
      method: "eth_chainId",
    });
    console.log("chain id ==> ", accounts, balance, chainId);
    setWallet({ accounts, balance, chainId });
    getUsdtBal(accounts, chainId);
  };

  const handleConnect = async () => {
    if (!window.ethereum) {
      Swal.fire({
        icon: "error",
        title: `Install "MetaMask chrome extension" for Desktop`,
        showConfirmButton: false,
        timer: 5000,
        customClass:
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme"),
      });
      return false;
    }
    let accounts = await window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then((data) => {
        console.log("kkkk ==> ", data);
        updateWallet(data);
      })
      .catch((error) => {
        console.log(error, "bbbbbb");
      });
  };
  const handleGetAmount = async (event) => {
    setInputAmount(event.target.value);
  };
  const deposit_balance = useMutation(async (payload) => {
    try {
      const res = await userPrivateRequest.post(`user/deposit`, payload);

      return res.data;
    } catch (error) {
      console.log(error, "errorerrorerror");
      errorHandler(error);
    }
  });
  const switchChain = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        Swal.fire({
          icon: "warning",
          title:
            "You Don't Have BSC Network In Your Wallet, Please Configure To Continue",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
        console.log(switchError);
      }
    }
  };
  const getDataFieldValue = (tokenRecipientAddress, tokenAmount) => {
    const web3 = new Web3();
    const TRANSFER_FUNCTION_ABI = {
      constant: false,
      inputs: [
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transfer",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    };

    return web3.eth.abi.encodeFunctionCall(TRANSFER_FUNCTION_ABI, [
      tokenRecipientAddress,
      tokenAmount,
    ]);
  };
  const handleTransaction = async () => {
    // let buyLimit = 10 / process.env.REACT_APP_COIN_PRICE;
    // setDistributeToken(true);
    // setDistributeRefToken(true);
    if (
      parseFloat(props.data?.depositSettings?.deposit_min_val) >
      parseFloat(inputAmount)
    ) {
      Swal.fire({
        icon: "warning",
        title: `you can not deposit less than ${props.data?.depositSettings?.deposit_min_val} USDT`,
        showConfirmButton: false,
        timer: 3000,
        customClass:
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme"),
      });
      return false;
    }
    if (wallet.accounts.length > 0) {
      const num256 = parseFloat(inputAmount) * 10 ** 18;
      const amt = "0x" + num256.toString(16);
      var transParams = {};
      console.log(usdtBal, inputAmount);
      if (parseFloat(usdtBal) < parseFloat(inputAmount)) {
        Swal.fire({
          icon: "warning",
          title:
            "The bot is indicating that you do not have enough USDT (BEP20) balance.<br/> <br/> Please make sure you have at least 100 USDT (BEP20) and $1 equivalent BNB (gas fee) in your wallet.",
          showConfirmButton: false,
          timer: 5000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
        return false;
      }
      transParams = {
        from: wallet.accounts[0],
        to: "0x55d398326f99059ff775485246999027b3197955",
        //gasPrice: Number(3000000000).toString(16),
        gas: Number(300000).toString(16),
        data: getDataFieldValue(process.env.REACT_APP_SYSTEM_WALLET, amt),
      };

      //console.log(transParams)
      //window.postMessage(transParams, '*');
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [transParams],
        })
        .then((txhash) => {
          if (txhash) {
            // setTransactionUrl("https://bscscan.com/tx/" + txhash);
            // setIsOpen(true);

            const data = {
              address: wallet.accounts[0],
              amount: inputAmount,
              transaction_id: txhash,
            };
            deposit_balance
              .mutateAsync(data)
              .then((data) => {
                fetchDataWithToken("user/dashboard", props.setData);

                Swal.fire({
                  icon: "success",
                  title: data?.message ?? "Successfully update",
                  showConfirmButton: false,
                  timer: 3000,
                  customClass:
                    (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                      "GreenFieldsTheme") ||
                    (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                      "NebulaFighterTheme") ||
                    (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                      "DarkSpacesTheme") ||
                    (localStorage.getItem("appTheme") == "PureLightTheme" &&
                      "PureLightTheme") ||
                    (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                      "GreyGooseTheme") ||
                    (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                      "PurpleFlowTheme"),
                });
              })
              .catch((err) => {
                console.log(err, "errerrerrerr");
                Swal.fire({
                  icon: "error",
                  title: err?.message ?? "Something went wrong",
                  showConfirmButton: false,
                  timer: 3000,
                  customClass:
                    (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
                      "GreenFieldsTheme") ||
                    (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
                      "NebulaFighterTheme") ||
                    (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
                      "DarkSpacesTheme") ||
                    (localStorage.getItem("appTheme") == "PureLightTheme" &&
                      "PureLightTheme") ||
                    (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                      "GreyGooseTheme") ||
                    (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
                      "PurpleFlowTheme"),
                });
              });
            setInputAmount(0);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      //   toast.error("Minimum buy is " + buyLimit + "$DWRK tokens", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });

      console.log('Minimum buy is " + buyLimit + "$DWRK tokens');
    }
  };
  const getUsdtBal = async (accounts, chainId) => {
    if (chainId == "0x38") {
      try {
        const web3 = new Web3(window.ethereum);

        const balanceAbi = [
          {
            constant: true,
            inputs: [{ name: "_owner", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "balance", type: "uint256" }],
            payable: false,
            type: "function",
          },
        ];
        const tokenInst = new web3.eth.Contract(
          balanceAbi,
          "0x55d398326f99059ff775485246999027b3197955"
        );
        const uBal = formatBalance(
          await tokenInst.methods.balanceOf(accounts[0]).call()
        );
        if (!isNaN(uBal)) {
          setUsdtBal(uBal);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Eth network dont have USDT BEP-20",
          showConfirmButton: false,
          timer: 3000,
          customClass:
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme"),
        });
      }
    }
  };
  const handleClickDeposit = () => {
    if (props.data?.depositSettings?.deposit_status == 1) {
      setOpenDeposit(true);
    } else {
      Swal.fire({
        icon: "warning",
        title: "You can not deposit now, please try again later",
        showConfirmButton: false,
        timer: 3000,
        customClass:
          (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
            "GreenFieldsTheme") ||
          (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
            "NebulaFighterTheme") ||
          (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
            "DarkSpacesTheme") ||
          (localStorage.getItem("appTheme") == "PureLightTheme" &&
            "PureLightTheme") ||
          (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
            "GreyGooseTheme") ||
          (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
            "PurpleFlowTheme"),
      });
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const formatHex = (hexString, prefixLength = 6, suffixLength = 6) => {
    // Check if the input is a valid hexadecimal string
    if (!hexString) {
      return "Invalid address";
    }

    const prefix = hexString.slice(0, prefixLength);
    const suffix = hexString.slice(-suffixLength);

    return `${prefix}......${suffix}`;
  };

  const handleClickCopy = (text) => {
    window.navigator.clipboard.writeText(text);
    Swal.fire({
      icon: "success",
      title: "Copied",
      showConfirmButton: false,
      timer: 3000,
      customClass:
        (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
          "GreenFieldsTheme") ||
        (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
          "NebulaFighterTheme") ||
        (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
          "DarkSpacesTheme") ||
        (localStorage.getItem("appTheme") == "PureLightTheme" &&
          "PureLightTheme") ||
        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
          "GreyGooseTheme") ||
        (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
          "PurpleFlowTheme"),
    });
  };

  return (
    <Card>
      <Grid spacing={0} container>
        <Grid item xs={12} md={6}>
          <Box p={4}>
            <Typography
              sx={{
                pb: 3,
              }}
              variant="h4"
            >
              {t("Portfolio value")}
            </Typography>
            <Box>
              <Typography variant="h1" gutterBottom>
                ${props.data?.total_balance}
              </Typography>
              {/* <Typography
                variant="h4"
                fontWeight="normal"
                color="text.secondary"
              >
                1.0045983485234 BTC
              </Typography> */}
              <Box
                display="flex"
                sx={{
                  py: 4,
                }}
                alignItems="center"
              >
                {props.data?.pnl < 0 ? (
                  <AvatarError
                    sx={{
                      mr: 2,
                    }}
                    variant="rounded"
                  >
                    <TrendingDown fontSize="large" />
                  </AvatarError>
                ) : (
                  <AvatarSuccess
                    sx={{
                      mr: 2,
                    }}
                    variant="rounded"
                  >
                    <TrendingUp fontSize="large" />
                  </AvatarSuccess>
                )}
                <Box>
                  <Typography
                    variant="h4"
                    color={
                      props?.data?.pnl < 0
                        ? "error"
                        : props?.data?.pnl == 0
                        ? ""
                        : "success"
                    }
                  >
                    {props?.data?.pnl < 0
                      ? "-"
                      : props?.data?.pnl == 0
                      ? ""
                      : "+"}{" "}
                    ${Math.abs(props?.data?.pnl).toFixed(2)}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    {t("this month")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {openDeposit ? (
              <Grid container spacing={2} alignItems="center">
                {wallet?.accounts[0] ? (
                  <Grid sm={12} item xs={12}>
                    <Typography variant="subtitle2" noWrap>
                      <DotLegend
                        style={{
                          animation: `pulse 1s infinite`,
                          background: `${theme.colors.success.main}`,
                        }}
                      />
                      {t("Wallet Connected (" + wallet.accounts[0] + ")")}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid sm={12} item xs={12}>
                    <Typography variant="subtitle2">
                      <DotLegend
                        style={{
                          animation: `pulse 1s infinite`,
                          background: `${theme.colors.error.main}`,
                        }}
                      />
                      {t(
                        'Use "MetaMask chrome extension" for Desktop & "Trust Wallet" for Mobile '
                      )}
                    </Typography>
                  </Grid>
                )}

                <Grid sm={4} item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    autoFocus
                    label={t("Amount")}
                    name="deposit"
                    onChange={handleGetAmount}
                    value={inputAmount}
                    variant="outlined"
                    type="number"
                    sx={{
                      my: 0,
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  {wallet.accounts.length == 0 ? (
                    <Button
                      fullWidth
                      variant="outlined"
                      size="large"
                      onClick={() => handleConnect()}
                    >
                      {t("Connect Wallet")}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="outlined"
                      size="large"
                      onClick={
                        wallet.chainId !== "0x38"
                          ? () => switchChain("0x38")
                          : handleTransaction
                      }
                    >
                      {wallet.chainId !== "0x38"
                        ? t("Switch to BSC")
                        : t("Deposit")}
                    </Button>
                  )}
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setOpenDeposit(false)}
                    size="large"
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid sm item>
                  <Button
                    fullWidth
                    variant="outlined"
                    LinkComponent={NavLink}
                    to="/withdrawal-history"
                  >
                    {t("Withdraw")}
                  </Button>
                </Grid>
                <Grid sm item>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleOpenModal}
                  >
                    {t("Deposit")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid
          sx={{
            position: "relative",
          }}
          display="flex"
          alignItems="center"
          item
          xs={12}
          md={6}
        >
          <Box
            component="span"
            sx={{
              display: { xs: "none", md: "inline-block" },
            }}
          >
            <Divider absolute orientation="vertical" />
          </Box>
          <Box py={4} px={4} flex={1}>
            <Grid container spacing={0}>
              <Grid
                xs={12}
                sm={5}
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {props.data?.spot_invest ||
                props.data?.future_invest ||
                props.data?.presale_invest ||
                props.data?.hold_invest ? (
                  <Chart
                    height={250}
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                  />
                ) : (
                  <>
                    {props.data && (
                      <Chart
                        height={250}
                        options={chartOptions_default}
                        series={chartSeries_data}
                        type="donut"
                      />
                    )}
                  </>
                )}
              </Grid>
              <Grid xs={12} sm={7} item display="flex" alignItems="center">
                <List
                  disablePadding
                  sx={{
                    width: "100%",
                  }}
                >
                  <ListItem disableGutters>
                    <AvatarWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="2em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"
                          fill={
                            localStorage.getItem("appTheme") ==
                              "GreenFieldsTheme" ||
                            localStorage.getItem("appTheme") ==
                              "NebulaFighterTheme" ||
                            localStorage.getItem("appTheme") ==
                              "DarkSpacesTheme"
                              ? "#fff"
                              : "rgba(34, 51, 84, 0.5)"
                          }
                        />
                      </svg>
                    </AvatarWrapper>
                    <ListItemText
                      primary={`${t("Spot Trading")}`}
                      primaryTypographyProps={{
                        variant: "h5",
                        noWrap: true,
                      }}
                      secondary={`${t("PNL")}`}
                      secondaryTypographyProps={{
                        variant: "subtitle2",
                        noWrap: true,
                      }}
                    />
                    <Box>
                      <Typography align="right" variant="h4" noWrap>
                        ${props.data?.spot_invest}
                      </Typography>
                      <Typography align="right" variant="span" noWrap>
                        ${props.data?.spot_pnl}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem disableGutters>
                    <AvatarWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.5em"
                        viewBox="0 0 576 512"
                      >
                        <path
                          d="M137.4 502.6c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7V288H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H448V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 109.3V224H192 128 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96V402.7L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96zM128 192h64V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V192zM448 320H384V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320z"
                          fill={
                            localStorage.getItem("appTheme") ==
                              "GreenFieldsTheme" ||
                            localStorage.getItem("appTheme") ==
                              "NebulaFighterTheme" ||
                            localStorage.getItem("appTheme") ==
                              "DarkSpacesTheme"
                              ? "#fff"
                              : "rgba(34, 51, 84, 0.5)"
                          }
                        />
                      </svg>
                    </AvatarWrapper>
                    <ListItemText
                      primary={`${t("Future Trading")}`}
                      primaryTypographyProps={{
                        variant: "h5",
                        noWrap: true,
                      }}
                      secondary={`${t("PNL")}`}
                      secondaryTypographyProps={{
                        variant: "subtitle2",
                        noWrap: true,
                      }}
                    />
                    <Box>
                      <Typography align="right" variant="h4" noWrap>
                        ${props.data?.future_invest}
                      </Typography>
                      <Typography align="right" variant="span" noWrap>
                        ${props.data?.future_pnl}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem disableGutters>
                    <AvatarWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.5em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z"
                          fill={
                            localStorage.getItem("appTheme") ==
                              "GreenFieldsTheme" ||
                            localStorage.getItem("appTheme") ==
                              "NebulaFighterTheme" ||
                            localStorage.getItem("appTheme") ==
                              "DarkSpacesTheme"
                              ? "#fff"
                              : "rgba(34, 51, 84, 0.5)"
                          }
                        />
                      </svg>
                    </AvatarWrapper>
                    <ListItemText
                      primary={`${t("Investment")}`}
                      primaryTypographyProps={{
                        variant: "h5",
                        noWrap: true,
                      }}
                      secondary={`${t("PNL")}`}
                      secondaryTypographyProps={{
                        variant: "subtitle2",
                        noWrap: true,
                      }}
                    />
                    <Box>
                      <Typography align="right" variant="h4" noWrap>
                        ${props.data?.presale_invest}
                      </Typography>
                      <Typography align="right" variant="span" noWrap>
                        ${props.data?.presale_pnl}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem disableGutters>
                    <AvatarWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.5em"
                        viewBox="0 0 576 512"
                      >
                        <path
                          d="M64 0C28.7 0 0 28.7 0 64V416c0 35.3 28.7 64 64 64H80l16 32h64l16-32H400l16 32h64l16-32h16c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM224 320a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-240a160 160 0 1 1 0 320 160 160 0 1 1 0-320zM480 221.3V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V221.3c-18.6-6.6-32-24.4-32-45.3c0-26.5 21.5-48 48-48s48 21.5 48 48c0 20.9-13.4 38.7-32 45.3z"
                          fill={
                            localStorage.getItem("appTheme") ==
                              "GreenFieldsTheme" ||
                            localStorage.getItem("appTheme") ==
                              "NebulaFighterTheme" ||
                            localStorage.getItem("appTheme") ==
                              "DarkSpacesTheme"
                              ? "#fff"
                              : "rgba(34, 51, 84, 0.5)"
                          }
                        />
                      </svg>
                    </AvatarWrapper>
                    <ListItemText
                      primary={`${t("Holding")}`}
                      primaryTypographyProps={{
                        variant: "h5",
                        noWrap: true,
                      }}
                      secondary={`${t("PNL")}`}
                      secondaryTypographyProps={{
                        variant: "subtitle2",
                        noWrap: true,
                      }}
                    />
                    <Box>
                      <Typography align="right" variant="h4" noWrap>
                        ${props.data?.hold_invest}
                      </Typography>
                      <Typography align="right" variant="span" noWrap>
                        ${props.data?.hold_pnl}
                      </Typography>
                    </Box>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle
            sx={{
              p: 2,
            }}
          >
            <Typography textAlign={"center"} variant="h4" gutterBottom>
              {t("Deposit")}
            </Typography>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              p: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid className="qrcode-wrap">
                  <QRCodeComponent data={getData?.wallet?.address} />
                </Grid>
                <Card
                  sx={{
                    mt: 2,
                  }}
                  fullWidth
                  // style={{backgroundColor: "#040506"}}
                >
                  <div className="qr-un-text">
                    <span
                      className={`qrcode-modal-title ${
                        (localStorage.getItem("appTheme") ==
                          "GreenFieldsTheme" &&
                          "GreenFieldsTheme") ||
                        (localStorage.getItem("appTheme") ==
                          "NebulaFighterTheme" &&
                          "NebulaFighterTheme") ||
                        (localStorage.getItem("appTheme") ==
                          "DarkSpacesTheme" &&
                          "DarkSpacesTheme") ||
                        (localStorage.getItem("appTheme") == "PureLightTheme" &&
                          "PureLightTheme") ||
                        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                          "GreyGooseTheme") ||
                        (localStorage.getItem("appTheme") ==
                          "PurpleFlowTheme" &&
                          "PurpleFlowTheme")
                      }`}
                    >
                      {t("Network")}
                    </span>
                    <div>{t("BEP20 BNB Smart chain (BSC)")}</div>
                  </div>
                  <Divider />
                  <Grid>
                    <div fullWidth className="d-flex w-full justify-between">
                      <div className="p-4">
                        <div
                          className={`qrcode-modal-title pt-4 ${
                            (localStorage.getItem("appTheme") ==
                              "GreenFieldsTheme" &&
                              "GreenFieldsTheme") ||
                            (localStorage.getItem("appTheme") ==
                              "NebulaFighterTheme" &&
                              "NebulaFighterTheme") ||
                            (localStorage.getItem("appTheme") ==
                              "DarkSpacesTheme" &&
                              "DarkSpacesTheme") ||
                            (localStorage.getItem("appTheme") ==
                              "PureLightTheme" &&
                              "PureLightTheme") ||
                            (localStorage.getItem("appTheme") ==
                              "GreyGooseTheme" &&
                              "GreyGooseTheme") ||
                            (localStorage.getItem("appTheme") ==
                              "PurpleFlowTheme" &&
                              "PurpleFlowTheme")
                          }`}
                        >
                          {t("Deposit Address")}
                        </div>
                        <span className="text">
                          {formatHex(getData?.wallet?.address)}
                        </span>
                      </div>
                      <Button
                        color="primary"
                        type="button"
                        className="p-none"
                        onClick={() =>
                          handleClickCopy(getData?.wallet?.address)
                        }
                      >
                        {t("Copy")}
                      </Button>
                    </div>
                  </Grid>
                </Card>
                <Card
                  sx={{
                    mt: 2,
                  }}
                  // style={{backgroundColor: "#040506"}}
                >
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {/* <TableRow>
                          <TableCell align="start">Gas Fee</TableCell>
                          <TableCell align="right">0.0001-3 BNB</TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell
                            className={`qrcode-modal-title ${
                              (localStorage.getItem("appTheme") ==
                                "GreenFieldsTheme" &&
                                "GreenFieldsTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "NebulaFighterTheme" &&
                                "NebulaFighterTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "DarkSpacesTheme" &&
                                "DarkSpacesTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PureLightTheme" &&
                                "PureLightTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "GreyGooseTheme" &&
                                "GreyGooseTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PurpleFlowTheme" &&
                                "PurpleFlowTheme")
                            }`}
                            align="start"
                          >
                            {t("Deposit To")}
                          </TableCell>
                          <TableCell align="right">{t("Deposit Wallet")}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={`qrcode-modal-title ${
                              (localStorage.getItem("appTheme") ==
                                "GreenFieldsTheme" &&
                                "GreenFieldsTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "NebulaFighterTheme" &&
                                "NebulaFighterTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "DarkSpacesTheme" &&
                                "DarkSpacesTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PureLightTheme" &&
                                "PureLightTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "GreyGooseTheme" &&
                                "GreyGooseTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PurpleFlowTheme" &&
                                "PurpleFlowTheme")
                            }`}
                            align="start"
                          >
                            {t("Minimum Deposit")}
                          </TableCell>
                          <TableCell align="right">
                            {props.data?.depositSettings?.deposit_min_val} USDT
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={`qrcode-modal-title ${
                              (localStorage.getItem("appTheme") ==
                                "GreenFieldsTheme" &&
                                "GreenFieldsTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "NebulaFighterTheme" &&
                                "NebulaFighterTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "DarkSpacesTheme" &&
                                "DarkSpacesTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PureLightTheme" &&
                                "PureLightTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "GreyGooseTheme" &&
                                "GreyGooseTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PurpleFlowTheme" &&
                                "PurpleFlowTheme")
                            }`}
                            align="start"
                          >
                            {t("Confirmations Req.")}
                          </TableCell>
                          <TableCell align="right">8 Block(s)</TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell className={`qrcode-modal-title ${
                        (localStorage.getItem("appTheme") ==
                          "GreenFieldsTheme" &&
                          "GreenFieldsTheme") ||
                        (localStorage.getItem("appTheme") ==
                          "NebulaFighterTheme" &&
                          "NebulaFighterTheme") ||
                        (localStorage.getItem("appTheme") ==
                          "DarkSpacesTheme" &&
                          "DarkSpacesTheme") ||
                        (localStorage.getItem("appTheme") == "PureLightTheme" &&
                          "PureLightTheme") ||
                        (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
                          "GreyGooseTheme") ||
                        (localStorage.getItem("appTheme") ==
                          "PurpleFlowTheme" &&
                          "PurpleFlowTheme")
                      }`} align="start">Approximate Time</TableCell>
                          <TableCell align="right">2-3 Min</TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell
                            className={`qrcode-modal-title ${
                              (localStorage.getItem("appTheme") ==
                                "GreenFieldsTheme" &&
                                "GreenFieldsTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "NebulaFighterTheme" &&
                                "NebulaFighterTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "DarkSpacesTheme" &&
                                "DarkSpacesTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PureLightTheme" &&
                                "PureLightTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "GreyGooseTheme" &&
                                "GreyGooseTheme") ||
                              (localStorage.getItem("appTheme") ==
                                "PurpleFlowTheme" &&
                                "PurpleFlowTheme")
                            }`}
                            align="start"
                          >
                            {t("Contract Address")}
                          </TableCell>
                          <TableCell align="right">
                            0x55......27b3197955
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => setOpen(false)}
                  fullWidth
                >
                  {t("Close")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </Card>
  );
}

export default AccountBalance;
