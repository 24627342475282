
export const convertDateTime = (originalTimestamp) => {
  if (!originalTimestamp) {
    return originalTimestamp
  }
  const dateObject = new Date(originalTimestamp);
  
  const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} 
    ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`;
  
  return formattedDate
}

export const getPackageCategoryLevel = (package_category_id) => {
  const map = {
    1: {
      title: "SPOT",
    },
    2: {
      title: "FUTURE",
    },
    3: {
      title: "INVESTMENT",
    },
    4: {
      title: "HOLDING",
    },
  };

  const { title } = map[package_category_id];
  return title
}
