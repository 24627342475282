import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Text from "src/components/Text";

const AvatarWrapperError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color:  ${theme.colors.error.main};
`
);

const AvatarWrapperSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color:  ${theme.colors.success.main};
`
);


function AccountSecurity(props) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Account Security")} />
      <Divider />
      <List disablePadding>
        <ListItem
          sx={{
            py: 2,
          }}
        >
          <ListItemAvatar>
            {props.user_info?.g2f_enabled == 1 ? (
              <AvatarWrapperSuccess>
                <LockTwoToneIcon />
              </AvatarWrapperSuccess>
            ) : (
              <AvatarWrapperError>
                <LockTwoToneIcon />
              </AvatarWrapperError>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={<Text color="black">{t("2FA Authentication")}</Text>}
            primaryTypographyProps={{
              variant: "body1",
              fontWeight: "bold",
              color: "textPrimary",
              gutterBottom: true,
              noWrap: true,
            }}
            secondary={
              <Text
                color={props.user_info?.g2f_enabled == 1 ? "success" : "error"}
              >
                {props.user_info?.g2f_enabled == 1
                  ? t("Enable")
                  : t("Disabled")}
              </Text>
            }
            secondaryTypographyProps={{ variant: "body2", noWrap: true }}
          />
          <Switch
            edge="end"
            color="primary"
            checked={props.user_info?.g2f_enabled == 1 ? true : false}
            readOnly
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            py: 2,
          }}
        >
          <ListItemAvatar>
            {props.user_info?.status == 1 ? (
              <AvatarWrapperSuccess>
                <PersonIcon />
              </AvatarWrapperSuccess>
            ) : (
              <AvatarWrapperError>
                <PersonIcon />
              </AvatarWrapperError>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={<Text color="black">{t("User Status")}</Text>}
            primaryTypographyProps={{
              variant: "body1",
              fontWeight: "bold",
              color: "textPrimary",
              gutterBottom: true,
              noWrap: true,
            }}
            secondary={
              <Text color={props.user_info?.status == 1 ? "success" : "error"}>
                {props.user_info?.status == 1 ? t("Active") : t("Inactive")}
              </Text>
            }
            secondaryTypographyProps={{ variant: "body2", noWrap: true }}
          />
          <Switch
            edge="end"
            color="primary"
            checked={props.user_info?.status == 1 ? true : false}
            readOnly
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            py: 2,
          }}
        >
          <ListItemAvatar>
            <AvatarWrapperSuccess>
              <EmailTwoToneIcon />
            </AvatarWrapperSuccess>
          </ListItemAvatar>
          <ListItemText
            primary={<Text color="black">{t("Email Verification")}</Text>}
            primaryTypographyProps={{
              variant: "body1",
              fontWeight: "bold",
              color: "textPrimary",
              gutterBottom: true,
              noWrap: true,
            }}
            secondary={
              <Text color="success">
                {props.user_info?.email_verified == 1
                  ? t("Completed")
                  : t("Not completed")}
              </Text>
            }
            secondaryTypographyProps={{ variant: "body2", noWrap: true }}
          />
          <Switch
            edge="end"
            color="primary"
            checked={props.user_info?.email_verified == 1 ? true : false}
            readOnly
          />
        </ListItem>
      </List>
    </Card>
  );
}

export default AccountSecurity;
