import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
    display: inline-flex;
    border-radius: ${theme.general.borderRadiusLg};
    height: 58px;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(13)};
    position: relative;
    margin-left: ${theme.spacing(1)};

    .MuiSvgIcon-root {
      transition: ${theme.transitions.create(["color"])};
      font-size: ${theme.typography.pxToRem(16)};
      color: ${theme.colors.alpha.trueWhite[50]};
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.alpha.black[10]};

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function Userbox() {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const { user, logout, adminLogout } = useAuth();

    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            if (user.role === "admin") {
                await adminLogout();
                navigate("/admin/login");
            } else {
                await logout();
                navigate("/");
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleProfileSettings = async () => {
        try {
            handleClose();
            if (user.role === "admin") {
                navigate("/admin/profile-settings/profile");
            } else {
                navigate("/profile-settings/profile");
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
                <Avatar variant="rounded" alt={user.name} src={user.avatar} />
                <Box
                    component="span"
                    sx={{
                        display: { xs: "none", sm: "inline-block" },
                    }}
                >
                    <ExpandMoreTwoToneIcon
                        sx={{
                            ml: 1,
                        }}
                    />
                </Box>
            </IconButtonPrimary>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <MenuUserBox
                    sx={{
                        minWidth: 210,
                    }}
                    display="flex"
                >
                    <Avatar
                        variant="rounded"
                        alt={user.name}
                        src={user.avatar}
                    />
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
                        <UserBoxDescription
                            variant="body2"
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                            color="primary"
                        >
                            {user.user_name}
                        </UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider
                    sx={{
                        mb: 0,
                    }}
                />
                <List
                    sx={{
                        p: 1,
                    }}
                    component="nav"
                >
                    <ListItem button onClick={handleProfileSettings}>
                        <AccountBoxTwoToneIcon fontSize="small" />
                        <ListItemText primary={t("Profile Settings")} />
                    </ListItem>
                </List>
                <Divider />
                <Box m={1}>
                    <Button color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon
                            sx={{
                                mr: 1,
                            }}
                        />
                        {t("Sign out")}
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

export default Userbox;
