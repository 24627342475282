const ruJSON = {

  'Available Balance':'доступный остаток',
  '2FA Authentication': 'Аутентификация 2FA',
  'AMOUNT': 'Количество',
  'Amount': 'Количество',
  'Account Security': 'Безопасность',
  'Buy New Perpetual SIP': 'Купить новый бессрочный SIP',
  'Completed': 'завершено',
  'Copyright © 2017-2024 Bitgrow. All Rights Reserved.': 'Авторское право © 2017-2024 Bitgrow. Все права защищены.',
  'Crypto Future Trading Investment Packages allow experienced investors to borrow funds and trade larger amounts of cryptocurrencies. This option is high-risk, high-reward, perfect for aggressive investors seeking substantial short-term gains.': 'Инвестиционные пакеты торговли фьючерсами позволяют опытным инвесторам занимать средства и торговать большими объемами криптовалют. Этот вариант сопряжен с высоким риском и высокой прибылью, идеально подходит для агрессивных инвесторов, стремящихся к существенной краткосрочной прибыли.',
  'Crypto Spot Trading Investment Packages offer immediate cryptocurrency trades at current market prices. Ideal for rapid returns, this investment package gives real-time exposure to the volatile and potentially profitable world of cryptocurrency.': 'Инвестиционные пакеты в криптовалютную спотовую торговлю предлагают немедленные сделки с криптовалютой по текущим рыночным ценам. Идеально подходящий для быстрой отдачи, этот инвестиционный пакет предоставляет доступ в режиме реального времени к изменчивому и потенциально прибыльному миру криптовалют.',
  'Current Week': 'Текущая неделя',
  'Description': 'описание',
  'DESCRIPTION': 'описание',
  'Designed to help your money grow': 'Создан, чтобы повысить ваш капитал',
  'Disabled': 'отключено',
  'Dive into the dynamic world of digital currencies with our comprehensive Crypto Investment, tailored for both novice and seasoned investors.': 'Окунитесь в динамичный мир цифровых валют с нашими комплексными криптоинвестициями, разработанными как для начинающих, так и для опытных инвесторов.',
  'Email': 'имэйл',
  'Email Verification': 'Верификация имэйла',
  'Email Verified': 'имэйл подтвержден',
  'English': 'Английский',
  'Enter your password to continue with the current session': 'Введите пароль, чтобы начать новую сессию',
  'Established in 2017, Bitgrow is a trading firm specializing in cryptocurrencies with an AUM of USD 10 million.': 'Основанная в 2017 году, Bitgrow является торговой компанией, специализирующейся на криптовалютах, с активами под управлением в размере 10 миллионов долларов США.',
  'Expected Weekly PNL [60:21:15:4]': 'Ожидаемая недельная прибыль и убыток',
  'Expected Yearly PNL [60:21:15:4]': 'Ожидаемая годовая прибыль и убыток',
  'Google Auth': 'Аутентификация Google',
  'Holding': 'долгосрочное хранение',
  'Holding Packages offer a long-term investment strategy, allowing investors to buy and hold cryptocurrencies for potential future appreciation. Ideal for those with a patient approach and strong belief in the future of digital currencies.': 'Hold пакеты предлагают долгосрочную инвестиционную стратегию, позволяя инвесторам покупать и удерживать криптовалюты для потенциального повышения курса в будущем. Идеально подходит для тех, у кого терпеливый подход и твердая вера в будущее цифровых валют.',
  'Inactive': 'Неактивно',
  'Investment': 'Инвестирование',
  "Investment Packages provide structured and diverse investment solutions, designed to grow your capital over time. Tailored to meet various risk-reward profiles, they promise the potential for lucrative returns through strategic asset allocation.": 'Инвестиционные пакеты предоставляют структурированные и разнообразные инвестиционные решения, предназначенные для увеличения вашего капитала с течением времени. Разработанные с учетом различных профилей соотношения риска и вознаграждения, они обещают потенциальную прибыль за счет стратегического распределения активов.',
  'Invitation Code': 'Код приглашения',
  "Keep your account secure by enabling two-factor authentication. Each time you login in . you'll be require to provide your password plus a randomly generate access code": 'Обеспечьте безопасность своей учетной записи, включив двухфакторную аутентификацию. Каждый раз, когда вы будете входить в систему,вам потребуется ввести пароль и сгенерированный код доступа',
  'Language Switcher': 'Переключатель языка',
  'Leverage your profits and amplify your earning potential with our Future Trading Investment, designed for the aggressive, risk-tolerant investor.': 'Используйте свою прибыль и увеличьте свой потенциал заработка с помощью наших будущих торговых инвестиций, разработанных для агрессивных, толерантных к риску инвесторов.',
  'No ': 'нет',
  'No Data Found': 'No Data Found',
  'Next': 'следующий',
  'PNL': 'прибыль и убыток',
  'Perpetual SIP Information': 'Информация постоянного SIP',
  'Perpetual SIP': 'постоянный SIP',
  'Pick Perpetual SIP': 'Выберите постоянный SIP',
  'Play the long game with our Crypto Holding Investment Package, designed for those who believe in the enduring value of virtual currencies.': "Играйте в долгую игру с нашим инвестиционным пакетом ''Долгое храниние“, разработанным для тех, кто верит в непреходящую ценность виртуальных валют.",
  'Portfolio value': 'Стоимость портфолио',
  'Previous Week': 'Прошлая неделя',
  'Promoter': 'промоутер',
  'Profile Settings': 'Настройки профиля',
  'Recent Activity': 'Недавние действия',
  'Research': 'Изучение',
  'SIP History': 'История СИП',
  'Scan qrcode or copy bellow code': '扫描二维Отсканируйте qr-код или скопируйте приведенный ниже код码或复制以下代码',
  'Session Timeout': 'Cеанс окончен',
  'Take your investing to the Next level. Let’s Start Today!': 'Направьте свои инвестиции на новый уровень. Давайте начнем сегодня!',
  'Technology': 'Технология',
  'The - is required': 'Параметр - является обязательным',
  'The code is required': 'Код обязателен для заполнения',
  'Trading': 'Торговля',
  'Unleash immediate profits with our Crypto Spot Trading Investment Packages, harnessing real-time cryptocurrency market trends.': 'Получите немедленную прибыль с помощью наших инвестиционных пакетов для спотовой торговли криптовалютами, используя тенденции рынка криптовалют в режиме реального времени',
  'User Status': 'Статус пользователя',
  'Username': 'Имя пользователя',
  'We are market makers for top protocols on the most popular blockchains. We enhance market efficiency by identifying mispricings and arbitraging them.': 'Мы маркет-мейкеры топовых протоколов на самых популярных блокчейнах. Мы повышаем эффективность рынка, выявляя ошибки в ценообразовании и устраняя их.',
  'We built our own in-house trading system to trade seamlessly on different centralized and decentralized venues 24x7 all year round.': 'Мы создали собственную торговую систему, чтобы беспрепятственно торговать на различных централизованных и децентрализованных площадках 24x7 круглый год.',
  'We explore new ideas and apply rigorous research to gain new perspectives. Along the way, we also turn them into handsome profits.': 'Мы изучаем новые идеи и применяем тщательные исследования, чтобы выйти на новые перспективы. Попутно мы также превращаем их в солидную прибыль.',
  'We live, sleep and breathe DeFi': 'Мы живем, спим и дышим DeFi',
  'Welcome': 'Добро пожаловать!',
  'What we do': 'Наша профориентация',
  'Who are we': 'Немного о нас',
  'Withdraw Amount': 'сумма снятия',
  'Withdraw Type': 'тип снятия',
  'Address': 'Адрес',
  'Enter the world of digital wealth. Smart contract and control!': 'Откройте мир цифрового богатства. Умный контракт и контроль!',
  'Change Password': 'Изменить пароль',
  'City': 'город',
  'FROM': 'от',
  'From': 'от',
  'Perpetual SIP by Category': 'бессрочный СИП по категориям',
  'Promoter Earnings': 'Заработок промоутинга',
  'Subscriptions': 'Подписки',
  'Perpetual SIP by Tier': 'бессрочный СИП по уровням',
  'Close': 'закрыть',
  'Tier': 'Уровень',
  'Tier 1': 'Уровень один',
  'Tier 2': 'Уровень два',
  'Tier 3': 'Уровень три',
  'Tier 4': 'Уровень четыре',
  'Tier 5': 'Уровень пять',
  'Tier 6': 'Уровень шесть',
  'Tier 7': 'Уровень семь',
  'Tier 8': 'Уровень восемь',
  'Tier 9': 'Уровень девять',
  'Confirm Password': 'подтвердить новый пароль',
  'Copy': 'копировать',
  'Country': 'Страна',
  'Dark': 'Тёмный',
  'DARK': 'Тёмный',
  'dashboard': 'рабочая панель',
  'Dashboard': 'рабочая панель',
  'Date': 'Время',
  'Deposit': 'пополнить ',
  'Edit Profile': 'Настройки профиля',
  'Enter 6 digit auth code': 'Введите 6-значный код авторизации',
  'Enter auth password': 'Введите пароль аутентификации',
  'Enter your password here': 'Введите свой пароль здесь',
  'Future': 'Фьючерсы',
  'Future Trading': 'Фьючерс торговля',
  'Here': 'введите сообщение здесь',
  'Light': 'Светлый',
  'LIGHT': 'Светлый',
  'Login': 'Войти',
  'Logout': 'выйти',
  'Name': 'Имя',
  'New password': 'новый пароль',
  'Old password': 'старый пароль',
  'OTP (Google Auth)': 'Google аутентификация C165',
  'Perpetual systematic investment plan (SIP)': 'Бессрочный систематический инвестиционный план (СИП)',
  'Phone': 'номер ',
  'Previous': 'предыдущий',
  'Profile': 'профиль',
  'Profile settings': 'Настройки профиля',
  'Register': 'Зарегистироваться',
  'Report': 'Жалобы ',
  'Sign out': 'Выйти',
  'Spot': 'Спот',
  'spot': 'Спот',
  'Spot Trading': 'Спот торговля',
  'Start Holding': 'начать долгосрочное хранение',
  'Start Investing': 'Начать инвестировать',
  'Start Trading': 'начать торговлю',
  'Start Trading (Future)': '(Фьючерсы)начать торговлю',
  'Start Trading (Spot)': '(Спот)начать торговлю',
  'Send via crypto network': 'отправить через криптосеть',
  'Send via email': 'отправить по имэйлу',
  'Support': 'Помощь',
  'Success': 'завершено',
  'Theme': 'Тема',
  'theme': 'Тема',
  'This month': 'этот месяц',
  'this month': 'этот месяц',
  'Transfer': 'трансфер',
  'tx type': 'Время',
  'TX Type': 'Время',
  'Update': 'обновить',
  'username': 'Имя пользователя',
  'Username': 'Имя пользователя',
  'Verify ': 'подтвердить',
  'Verify to continue': 'Верифицироваться и продолжить',
  'verify to continue': 'Верифицироваться и продолжить',
  'Withdraw': 'снятие',
  'Withdraw Information': 'Информация о снятии',
  'Wallets': 'Кошельки',
  'Wallet': 'Кошельки',
  'write your code here': 'Введите свой код здесь',
  'Yes': 'да',
  'zip code': 'Почтовый индекс',

  'AI Spot trading tools': 'Инструменты спотовой торговли с использованием ИИ',
  'AI Future trading tools': 'ИИ Инструменты для торговли в будущем',
  'Multiple pairs trading' : 'Торговля несколькими парами',
  'Automated rebalancing': 'Автоматическое перебалансирование',
  'Smart capital cover': 'Интеллектуальное покрытие капитала',
  'Traditional and Roth IRAs': 'Традиционные пенсионные счета и',
  'Weekly PNL Distribution': 'Еженедельное распределение прибылей и убытков',
  'Capital redemption - 7 Days': 'возврат капитала - 7 дней',

  'Next Gemstone Strategy': 'Стратегия Next Gemstone',
  "Mid Cap's Strategy": 'Стратегия Mid Cap',
  'Early bird investment': 'Инвестиции ранних пташек',
  "DeFi's investment": 'Инвестиции DeFi',
  "Traditional and Roth Individual retirement accounts": 'Традиционные пенсионные счета и',
  "Quarterly PNL Distribution": 'Ежеквартальное распределение прибылей и убытков',
  "Capital redemption - 90 Days": 'возврат капитала - 90 дней',

  "Lower counterparty risk": 'Снижение риска контрагента',
  "Segregate and protect assets": 'Разделение и защита активов',
  "Managed cold wallet": 'Управляемый холодный кошелек',
  "Smart baskets & rebalancing": 'Умные корзины и перебалансировка',
  "Fidelity growth Solutions": 'Решения для роста Fidelity',
  "Yearly PNL Distribution": 'Ежегодное распределение прибылей и убытков',
  "Capital redemption - 365 Days": 'Возврат капитала - 365 дней',

  "Transaction ID": 'ID Транзакции',
  "Created at": 'созданный в',
  "Dispatch Approximately": 'Возврат капитала',
  "No Data Found": 'Данные не найдены',
  "Status": 'статус',
  "Use Blockchain: Because your transaction history Matters.": 'Используйте блокчейн, Потому что cохранность истории ваших транзакций важнр.',
  "Investment today is a source of income tomorrow.": 'Инвестиции сегодня - это источник дохода завтра.',
  "Statistics": 'Статистика',
  "Current Year": 'текущий год',
  "Last Year": 'прошлый год',
  "All time": 'все время',
  "Total Investment": 'Общий объем инвестиций',
  "Total Profit": 'Общая прибыль',
  "Total Withdraw": 'Общая сумма снятия',
  "Capital Redeemed": 'Сумма возврата капитала',
  "TITLE": 'название',
  "Title": 'название',
  "CATEGORY": 'категория',
  "Category": 'категория',
  "TXID": 'Идентификатор транзакции',
  "TxID": 'Идентификатор транзакции',
  "ACTION": 'действие',
  "Action": 'действие',
  "Wallet History": 'История кошелька',
  "You are the lifeblood of the business. You won't lose hope, We promise.": 'Вы - источник жизненной силы бизнеса. Мы обещаем, что вы не потеряете надежду.',
  "Earnings": 'Прибыль',
  "Earning": 'Прибыль',
  "SIP Activation": 'Активация SIP',
  "SIP Activation (Community)": 'Активация SIP(Размер)',
  "Community": 'Размер',
  "Community (PNL)": 'Размер(прибыль и убыток)',
  "Community Size": 'Размер сообщества',
  "capital return - income on promotion": 'доход от промоутинга',
  "Capital Return": 'доход от промоутинга',
  "Recent Transactions": 'Недавние транзакции',
  "Profit": 'Прибыль',

  "Monday": 'Понедельник',
  "Tuesday": 'Вторник',
  "Wednesday": 'Среда',
  "Thursday": 'Четверг',
  "Friday": 'Пятница',
  "Saturday": 'Суббота',
  "Sunday": 'Воскресенье',

  "January": 'Январь',
  "February": 'Февраль',
  "March": 'Март',
  "April": 'Апрель',
  "May": 'Май',
  "June": 'Июнь',
  "July": 'Июль',
  "August": 'Август',
  "September": 'Сентябрь',
  "October": 'Октябрь',
  "November": 'Ноябрь',
  "December": 'Декабрь',

  "Jan": 'Январь',
  "Feb": 'Февраль',
  "Mar": 'Март',
  "Apr": 'Апрель',
  "May": 'Май',
  "Jun": 'Июнь',
  "Jul": 'Июль',
  "Aug": 'Август',
  "Sep": 'Сентябрь',
  "Oct": 'Октябрь',
  "Nov": 'Ноябрь',
  "Dec": 'Декабрь',

  "Support Ticket": 'Запрос в службу поддержки',
  "Others": 'другое',
  "Subject": 'вопрос',
  "Message here": 'введите сообщение здесь',
  "New world transactions, Powered by Blockchain.": 'Транзакции нового мира, основанные на блокчейне.',
  "TRANSACTION ID": 'ID Транзакции',
  "Transaction id": 'ID Транзакции',
  "Transaction Id": 'ID Транзакции',
  "Submit": 'отправить',
  "CREATED AT": 'созданный в',
  "Topic": 'тема',

  "English": 'Английский', 
  "German": 'Немецкий', 
  "Spanish": 'Испанский', 
  "French": 'Французский', 
  "Chinese": 'Китайский',  
  "Arabic": 'Арабский', 
  "Russian": 'Русский', 

  "Hurray! There are no archived chats!": 'Вау! Архивированных билетов нет!', 
  "Ticket List": 'Список билетов', 
  "Create Ticket": 'Список билетов', 
  "Ticket History": 'история билетов', 
  "PROMOTER LONG": 'Промоутинг "Лонг"', 
  "Promoter Long": 'Промоутинг "Лонг"', 
  "PROMOTER SHORT": 'Промоутинг "Шорт"', 
  "Promoter Short": 'Промоутинг "Шорт"', 
  "PNL LONG": '"Лонг" доходы', 
  "PNL Long": '"Лонг" доходы', 
  "PNL SHORT": '"Шорт" доходы', 
  "PNL Short": '"Шорт" доходы', 
  
};

export default ruJSON;
