import { Box, Drawer, styled, useTheme } from "@mui/material";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Logo from "src/components/LogoSign";
import Scrollbar from "src/components/Scrollbar";
import ThemeSettings from "src/components/ThemeSettings";
import { SidebarContext } from "src/contexts/SidebarContext";
import SidebarTopSection from "src/layouts/AccentHeaderLayout/Sidebar/SidebarTopSection";
import SidebarMenu from "src/layouts/BoxedSidebarLayout/Sidebar/SidebarMenu";
import BottomBar from "./BottomBar";
import { useTranslation } from "react-i18next";

const MainWrapper = styled(Box)(
    () => `
      flex: 1;
      display: flex;
      height: 100%;

      .footer-wrapper {
        overflow: hidden;
        height: 0;
        box-shadow: none;
        border: 0;
      }
`
);

const MainContent = styled(Box)(
    ({ theme }) => `
        margin-bottom: ${theme.header.height};
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);

const TopSection = styled(Box)(
    ({ theme }) => `
        margin: ${theme.spacing(2, 2)};
`
);

const BottomNavigationLayout = (props) => {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();
    const { t } = useTranslation();
    const menuItems_user = [
        {
            heading: "",
            items: [
                {
                    name: t("Dashboard"),
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path d="M0 72C0 49.9 17.9 32 40 32H88c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V72zM0 232c0-22.1 17.9-40 40-40H88c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V232zM128 392v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40H88c22.1 0 40 17.9 40 40zM160 72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V72zM288 232v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM160 392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392zM448 72v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM320 232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V232zM448 392v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40z" />
                        </svg>
                    ),
                    link: "/dashboard",
                },
                {
                    name: t("Perpetual SIP"),
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zM320 48c0-8.8-7.2-16-16-16s-16 7.2-16 16V96c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32v48c0 8.8 7.2 16 16 16s16-7.2 16-16V256c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32V48zm64 176v64c0 17.7 14.3 32 32 32v48c0 8.8 7.2 16 16 16s16-7.2 16-16V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V144c0-8.8-7.2-16-16-16s-16 7.2-16 16v48c-17.7 0-32 14.3-32 32zM160 128c-17.7 0-32 14.3-32 32V288c0 17.7 14.3 32 32 32v48c0 8.8 7.2 16 16 16s16-7.2 16-16V320c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48z" />
                        </svg>
                    ),
                    link: "/perpetual-sip",
                },
                {
                    name: t("Investment Pool"),
                    link: "investment-pool",
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                        >
                            <path d="M344 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V34.9c-6.9 1.8-13.8 4.5-20.2 8.2c-14.9 8.8-27.9 24.5-27.7 47.4c.1 22.1 13.1 36 26.6 44.1c11.4 6.9 25.6 11.2 36.4 14.4l1.9 .6c12.7 3.8 21.3 6.7 27.1 10.3c4 2.5 4 3.6 4 4.6l0 .2c0 2.3-.5 3.5-.8 4.1c-.4 .7-1.3 1.8-3.2 2.9c-4.1 2.6-11.2 4.4-19.2 4.1c-10.5-.3-20.2-3.6-33.9-8.3c-2.3-.8-4.8-1.6-7.3-2.5c-12.6-4.2-26.2 2.6-30.4 15.2s2.6 26.2 15.2 30.4c1.9 .6 3.9 1.3 6 2c7.4 2.6 16.2 5.6 25.6 7.8V232c0 13.3 10.7 24 24 24s24-10.7 24-24V221.4c7.4-1.8 14.6-4.7 21.3-8.8c15.3-9.5 27-25.9 26.7-48.5c-.3-22-12.8-36.3-26.4-44.9c-12-7.5-26.8-12-37.9-15.3l0 0-.9-.3c-12.9-3.9-21.6-6.6-27.4-10.1c-2.4-1.5-3.2-2.4-3.3-2.6c0-.1 0-.3 0-.6c0-1.6 .4-2.3 .7-2.8c.4-.6 1.4-1.8 3.5-3c4.7-2.8 12-4.5 19.4-4.4c9.1 .1 19.3 2.1 30.2 5c12.8 3.4 26-4.2 29.4-17s-4.2-26-17-29.4c-5.6-1.5-11.7-3-18.2-4.2V24zM144 136c0-39.8-32.2-72-72-72S0 96.2 0 136V352.2c0 31.8 12.6 62.3 35.1 84.9L103 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L69.1 403.1C55.6 389.6 48 371.3 48 352.2V136c0-13.3 10.7-24 24-24s24 10.7 24 24V281.9c0 25.6 10.2 50.2 28.3 68.4l15.9 15.9 0 0L167 393l0 0 16 16c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-16-16 0 0-26.7-26.7c-4-4-6.2-9.4-6.2-15c0-11.7 9.5-21.3 21.3-21.3c5.6 0 11 2.2 15 6.2l61.4 61.4C285.1 383 296 409.4 296 436.8V488c0 13.3 10.7 24 24 24s24-10.7 24-24V436.8c0-27.4 10.9-53.8 30.3-73.2l61.4-61.4c4-4 9.4-6.2 15-6.2c11.7 0 21.3 9.5 21.3 21.3c0 5.6-2.2 11-6.2 15L439 359l0 0-16 16c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l16-16 0 0 26.7-26.7 0 0 15.9-15.9c18.1-18.1 28.3-42.7 28.3-68.4V136c0-13.3 10.7-24 24-24s24 10.7 24 24V352.2c0 19.1-7.6 37.4-21.1 50.9L503 471c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l67.9-67.9c22.5-22.5 35.1-53 35.1-84.9V136c0-39.8-32.2-72-72-72s-72 32.2-72 72V264.8c-12.1-10.5-28-16.8-45.3-16.8c-18.4 0-36 7.3-49 20.3l-61.4 61.4c-7.7 7.7-14.5 16.2-20.4 25.3c-5.8-9.1-12.6-17.5-20.4-25.3l-61.4-61.4c-13-13-30.6-20.3-49-20.3c-17.3 0-33.1 6.3-45.3 16.8V136z" />
                        </svg>
                    ),
                },
                {
                    name: "DAO SIP",
                    link: "/dao-proposal",
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path d="M267.6 3c-7.2-4-16-4-23.2 0L17.6 128.1C6.7 134.1 0 145.5 0 157.9C0 176.8 15.2 192 34.1 192H477.9c18.8 0 34.1-15.2 34.1-34.1c0-12.4-6.7-23.8-17.6-29.8L267.6 3zM256 51.4L423.8 144H88.2L256 51.4zM112 224H64V384H56c-13.3 0-24 10.7-24 24s10.7 24 24 24H456c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V224H400V384H336V224H288V384H224V224H176V384H112V224zM0 488c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24z" />
                        </svg>
                    ),
                },
                {
                    name: "Promoters",
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                        >
                            <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H576V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm252.7 75.3c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9h88c8.8 0 16 7.2 16 16v88c0 6.5-3.9 12.3-9.9 14.8s-12.9 1.1-17.4-3.5l-27-27L401 273c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 55.7-55.7-27-27zM64 224a96 96 0 1 1 192 0A96 96 0 1 1 64 224zM0 485.3C0 411.7 59.7 352 133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3z" />
                        </svg>
                    ),
                    link: "/promoters",
                },

                {
                    name: "Wallet",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 576 512"
                        >
                            <path d="M400 96l0 .7c-5.3-.4-10.6-.7-16-.7H256c-16.5 0-32.5 2.1-47.8 6c-.1-2-.2-4-.2-6c0-53 43-96 96-96s96 43 96 96zm-16 32c3.5 0 7 .1 10.4 .3c4.2 .3 8.4 .7 12.6 1.3C424.6 109.1 450.8 96 480 96h11.5c10.4 0 18 9.8 15.5 19.9l-13.8 55.2c15.8 14.8 28.7 32.8 37.5 52.9H544c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H512c-9.1 12.1-19.9 22.9-32 32v64c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V448H256v32c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V416c-34.9-26.2-58.7-66.3-63.2-112H68c-37.6 0-68-30.4-68-68s30.4-68 68-68h4c13.3 0 24 10.7 24 24s-10.7 24-24 24H68c-11 0-20 9-20 20s9 20 20 20H99.2c12.1-59.8 57.7-107.5 116.3-122.8c12.9-3.4 26.5-5.2 40.5-5.2H384zm64 136a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />
                        </svg>
                    ),
                    link: "/wallet",
                },
                {
                    name: "Support",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 512 512"
                        >
                            <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                        </svg>
                    ),
                    items: [
                        {
                            name: "Ticket History",
                            link: "/support",
                        },
                        {
                            name: "Create Ticket",
                            link: "/support/create-ticket",
                        },
                    ],
                },
                {
                    name: "Report",
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path d="M32 96l320 0V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l96 96c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-96 96c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160L32 160c-17.7 0-32-14.3-32-32s14.3-32 32-32zM480 352c17.7 0 32 14.3 32 32s-14.3 32-32 32H160v64c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-96-96c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6l96-96c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 64H480z" />
                        </svg>
                    ),
                    items: [
                        {
                            name: "Deposit History",
                            link: "/deposit-history",
                        },
                        {
                            name: "Withdraw History",
                            link: "/withdrawal-history",
                        },
                    ],
                },
            ],
        },
    ];
    const menuItems_user_res = [
        {
            heading: "",
            items: [
                {
                    name: "Dashboard",
                    icon: (
                        <svg
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="#223354"
                                d="M0 72C0 49.9 17.9 32 40 32H88c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V72zM0 232c0-22.1 17.9-40 40-40H88c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V232zM128 392v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40H88c22.1 0 40 17.9 40 40zM160 72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V72zM288 232v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM160 392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392zM448 72v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM320 232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V232zM448 392v48c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40z"
                            />
                        </svg>
                    ),
                    link: "/dashboard",
                },
                {
                    name: "Perpetual SIP",
                    icon: (
                        <svg
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="#223354"
                                d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zM320 48c0-8.8-7.2-16-16-16s-16 7.2-16 16V96c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32v48c0 8.8 7.2 16 16 16s16-7.2 16-16V256c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32V48zm64 176v64c0 17.7 14.3 32 32 32v48c0 8.8 7.2 16 16 16s16-7.2 16-16V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V144c0-8.8-7.2-16-16-16s-16 7.2-16 16v48c-17.7 0-32 14.3-32 32zM160 128c-17.7 0-32 14.3-32 32V288c0 17.7 14.3 32 32 32v48c0 8.8 7.2 16 16 16s16-7.2 16-16V320c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48z"
                            />
                        </svg>
                    ),
                    link: "/perpetual-sip",
                },
                {
                    name: "Investment Pool",
                    link: "investment-pool",
                    icon: (
                        <svg
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                        >
                            <path
                                fill="#223354"
                                d="M344 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V34.9c-6.9 1.8-13.8 4.5-20.2 8.2c-14.9 8.8-27.9 24.5-27.7 47.4c.1 22.1 13.1 36 26.6 44.1c11.4 6.9 25.6 11.2 36.4 14.4l1.9 .6c12.7 3.8 21.3 6.7 27.1 10.3c4 2.5 4 3.6 4 4.6l0 .2c0 2.3-.5 3.5-.8 4.1c-.4 .7-1.3 1.8-3.2 2.9c-4.1 2.6-11.2 4.4-19.2 4.1c-10.5-.3-20.2-3.6-33.9-8.3c-2.3-.8-4.8-1.6-7.3-2.5c-12.6-4.2-26.2 2.6-30.4 15.2s2.6 26.2 15.2 30.4c1.9 .6 3.9 1.3 6 2c7.4 2.6 16.2 5.6 25.6 7.8V232c0 13.3 10.7 24 24 24s24-10.7 24-24V221.4c7.4-1.8 14.6-4.7 21.3-8.8c15.3-9.5 27-25.9 26.7-48.5c-.3-22-12.8-36.3-26.4-44.9c-12-7.5-26.8-12-37.9-15.3l0 0-.9-.3c-12.9-3.9-21.6-6.6-27.4-10.1c-2.4-1.5-3.2-2.4-3.3-2.6c0-.1 0-.3 0-.6c0-1.6 .4-2.3 .7-2.8c.4-.6 1.4-1.8 3.5-3c4.7-2.8 12-4.5 19.4-4.4c9.1 .1 19.3 2.1 30.2 5c12.8 3.4 26-4.2 29.4-17s-4.2-26-17-29.4c-5.6-1.5-11.7-3-18.2-4.2V24zM144 136c0-39.8-32.2-72-72-72S0 96.2 0 136V352.2c0 31.8 12.6 62.3 35.1 84.9L103 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L69.1 403.1C55.6 389.6 48 371.3 48 352.2V136c0-13.3 10.7-24 24-24s24 10.7 24 24V281.9c0 25.6 10.2 50.2 28.3 68.4l15.9 15.9 0 0L167 393l0 0 16 16c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-16-16 0 0-26.7-26.7c-4-4-6.2-9.4-6.2-15c0-11.7 9.5-21.3 21.3-21.3c5.6 0 11 2.2 15 6.2l61.4 61.4C285.1 383 296 409.4 296 436.8V488c0 13.3 10.7 24 24 24s24-10.7 24-24V436.8c0-27.4 10.9-53.8 30.3-73.2l61.4-61.4c4-4 9.4-6.2 15-6.2c11.7 0 21.3 9.5 21.3 21.3c0 5.6-2.2 11-6.2 15L439 359l0 0-16 16c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l16-16 0 0 26.7-26.7 0 0 15.9-15.9c18.1-18.1 28.3-42.7 28.3-68.4V136c0-13.3 10.7-24 24-24s24 10.7 24 24V352.2c0 19.1-7.6 37.4-21.1 50.9L503 471c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l67.9-67.9c22.5-22.5 35.1-53 35.1-84.9V136c0-39.8-32.2-72-72-72s-72 32.2-72 72V264.8c-12.1-10.5-28-16.8-45.3-16.8c-18.4 0-36 7.3-49 20.3l-61.4 61.4c-7.7 7.7-14.5 16.2-20.4 25.3c-5.8-9.1-12.6-17.5-20.4-25.3l-61.4-61.4c-13-13-30.6-20.3-49-20.3c-17.3 0-33.1 6.3-45.3 16.8V136z"
                            />
                        </svg>
                    ),
                },
                {
                    name: "DAO SIP",
                    link: "/dao-proposal",
                    icon: (
                        <svg
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="#223354"
                                d="M267.6 3c-7.2-4-16-4-23.2 0L17.6 128.1C6.7 134.1 0 145.5 0 157.9C0 176.8 15.2 192 34.1 192H477.9c18.8 0 34.1-15.2 34.1-34.1c0-12.4-6.7-23.8-17.6-29.8L267.6 3zM256 51.4L423.8 144H88.2L256 51.4zM112 224H64V384H56c-13.3 0-24 10.7-24 24s10.7 24 24 24H456c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V224H400V384H336V224H288V384H224V224H176V384H112V224zM0 488c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24z"
                            />
                        </svg>
                    ),
                },
               
                {
                    name: "Promoters",
                    icon: (
                        <svg
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                        >
                            <path
                                fill="#223354"
                                d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H576V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm252.7 75.3c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9h88c8.8 0 16 7.2 16 16v88c0 6.5-3.9 12.3-9.9 14.8s-12.9 1.1-17.4-3.5l-27-27L401 273c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 55.7-55.7-27-27zM64 224a96 96 0 1 1 192 0A96 96 0 1 1 64 224zM0 485.3C0 411.7 59.7 352 133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3z"
                            />
                        </svg>
                    ),
                    link: "/promoters",
                },

                {
                    name: "Wallet",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 576 512"
                        >
                            <path
                                fill="#223354"
                                d="M400 96l0 .7c-5.3-.4-10.6-.7-16-.7H256c-16.5 0-32.5 2.1-47.8 6c-.1-2-.2-4-.2-6c0-53 43-96 96-96s96 43 96 96zm-16 32c3.5 0 7 .1 10.4 .3c4.2 .3 8.4 .7 12.6 1.3C424.6 109.1 450.8 96 480 96h11.5c10.4 0 18 9.8 15.5 19.9l-13.8 55.2c15.8 14.8 28.7 32.8 37.5 52.9H544c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H512c-9.1 12.1-19.9 22.9-32 32v64c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V448H256v32c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V416c-34.9-26.2-58.7-66.3-63.2-112H68c-37.6 0-68-30.4-68-68s30.4-68 68-68h4c13.3 0 24 10.7 24 24s-10.7 24-24 24H68c-11 0-20 9-20 20s9 20 20 20H99.2c12.1-59.8 57.7-107.5 116.3-122.8c12.9-3.4 26.5-5.2 40.5-5.2H384zm64 136a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"
                            />
                        </svg>
                    ),
                    link: "/wallet",
                },
                {
                    name: "Support",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="#223354"
                                d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"
                            />
                        </svg>
                    ),
                    items: [
                        {
                            name: "Ticket History",
                            link: "/support",
                        },
                        {
                            name: "Create Ticket",
                            link: "/support/create-ticket",
                        },
                    ],
                },
                {
                    name: "Report",
                    icon: (
                        <svg
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="#223354"
                                d="M32 96l320 0V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l96 96c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-96 96c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160L32 160c-17.7 0-32-14.3-32-32s14.3-32 32-32zM480 352c17.7 0 32 14.3 32 32s-14.3 32-32 32H160v64c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-96-96c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6l96-96c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 64H480z"
                            />
                        </svg>
                    ),
                    items: [
                        {
                            name: "Deposit History",
                            link: "/deposit-history",
                        },
                        {
                            name: "Withdraw History",
                            link: "/withdrawal-history",
                        },
                    ],
                },
                {
                    name: "Settings",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="#223354"
                                d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                            />
                        </svg>
                    ),
                    link: "/profile-settings/profile",
                },
            ],
        },
    ];

    const menuItems_admin = [
        {
            heading: "",
            items: [
                {
                    name: "Dashboard",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 512 512"
                        >
                            <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm320 96c0-26.9-16.5-49.9-40-59.3V88c0-13.3-10.7-24-24-24s-24 10.7-24 24V292.7c-23.5 9.5-40 32.5-40 59.3c0 35.3 28.7 64 64 64s64-28.7 64-64zM144 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm-16 80a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM400 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                        </svg>
                    ),
                    link: "/admin/dashboard",
                },
                {
                    name: "Users",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 640 512"
                        >
                            <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                        </svg>
                    ),
                    link: "/admin/users",
                },

                {
                    name: "Investment Pool",
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                        >
                            <path d="M344 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V34.9c-6.9 1.8-13.8 4.5-20.2 8.2c-14.9 8.8-27.9 24.5-27.7 47.4c.1 22.1 13.1 36 26.6 44.1c11.4 6.9 25.6 11.2 36.4 14.4l1.9 .6c12.7 3.8 21.3 6.7 27.1 10.3c4 2.5 4 3.6 4 4.6l0 .2c0 2.3-.5 3.5-.8 4.1c-.4 .7-1.3 1.8-3.2 2.9c-4.1 2.6-11.2 4.4-19.2 4.1c-10.5-.3-20.2-3.6-33.9-8.3c-2.3-.8-4.8-1.6-7.3-2.5c-12.6-4.2-26.2 2.6-30.4 15.2s2.6 26.2 15.2 30.4c1.9 .6 3.9 1.3 6 2c7.4 2.6 16.2 5.6 25.6 7.8V232c0 13.3 10.7 24 24 24s24-10.7 24-24V221.4c7.4-1.8 14.6-4.7 21.3-8.8c15.3-9.5 27-25.9 26.7-48.5c-.3-22-12.8-36.3-26.4-44.9c-12-7.5-26.8-12-37.9-15.3l0 0-.9-.3c-12.9-3.9-21.6-6.6-27.4-10.1c-2.4-1.5-3.2-2.4-3.3-2.6c0-.1 0-.3 0-.6c0-1.6 .4-2.3 .7-2.8c.4-.6 1.4-1.8 3.5-3c4.7-2.8 12-4.5 19.4-4.4c9.1 .1 19.3 2.1 30.2 5c12.8 3.4 26-4.2 29.4-17s-4.2-26-17-29.4c-5.6-1.5-11.7-3-18.2-4.2V24zM144 136c0-39.8-32.2-72-72-72S0 96.2 0 136V352.2c0 31.8 12.6 62.3 35.1 84.9L103 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L69.1 403.1C55.6 389.6 48 371.3 48 352.2V136c0-13.3 10.7-24 24-24s24 10.7 24 24V281.9c0 25.6 10.2 50.2 28.3 68.4l15.9 15.9 0 0L167 393l0 0 16 16c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-16-16 0 0-26.7-26.7c-4-4-6.2-9.4-6.2-15c0-11.7 9.5-21.3 21.3-21.3c5.6 0 11 2.2 15 6.2l61.4 61.4C285.1 383 296 409.4 296 436.8V488c0 13.3 10.7 24 24 24s24-10.7 24-24V436.8c0-27.4 10.9-53.8 30.3-73.2l61.4-61.4c4-4 9.4-6.2 15-6.2c11.7 0 21.3 9.5 21.3 21.3c0 5.6-2.2 11-6.2 15L439 359l0 0-16 16c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l16-16 0 0 26.7-26.7 0 0 15.9-15.9c18.1-18.1 28.3-42.7 28.3-68.4V136c0-13.3 10.7-24 24-24s24 10.7 24 24V352.2c0 19.1-7.6 37.4-21.1 50.9L503 471c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l67.9-67.9c22.5-22.5 35.1-53 35.1-84.9V136c0-39.8-32.2-72-72-72s-72 32.2-72 72V264.8c-12.1-10.5-28-16.8-45.3-16.8c-18.4 0-36 7.3-49 20.3l-61.4 61.4c-7.7 7.7-14.5 16.2-20.4 25.3c-5.8-9.1-12.6-17.5-20.4-25.3l-61.4-61.4c-13-13-30.6-20.3-49-20.3c-17.3 0-33.1 6.3-45.3 16.8V136z" />
                        </svg>
                    ),
                    items: [
                        {
                            name: "Investment Pool",
                            link: "/admin/investment-pool",
                        },
                        {
                            name: "Pool PNL History",
                            link: "/admin/pool-pnl-history",
                        },
                        {
                            name: "Pool Investment History",
                            link: "/admin/pool-investment-history",
                        },
                        {
                            name: "Pool PNL Distribution History",
                            link: "/admin/pool-pnl-distribution-history",
                        },
                    ],
                },

                {
                    name: "DAO SIP",
                    icon: (
                        <svg
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path d="M267.6 3c-7.2-4-16-4-23.2 0L17.6 128.1C6.7 134.1 0 145.5 0 157.9C0 176.8 15.2 192 34.1 192H477.9c18.8 0 34.1-15.2 34.1-34.1c0-12.4-6.7-23.8-17.6-29.8L267.6 3zM256 51.4L423.8 144H88.2L256 51.4zM112 224H64V384H56c-13.3 0-24 10.7-24 24s10.7 24 24 24H456c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V224H400V384H336V224H288V384H224V224H176V384H112V224zM0 488c0 13.3 10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24z" />
                        </svg>
                    ),
                    items: [
                        {
                            name: "DAO Proposal Category",
                            link: "/admin/dao-category",
                        },
                        {
                            name: "Proposal History",
                            link: "/admin/proposal-history",
                        },
                    ],
                },
                {
                    name: "Perpetual SIP",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 576 512"
                        >
                            <path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5 36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7 7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1 11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z" />
                        </svg>
                    ),
                    link: "/admin/packages",
                },
                {
                    name: "Support",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 512 512"
                        >
                            <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                        </svg>
                    ),
                    link: "/admin/support",
                },
                {
                    name: "Reports",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 640 512"
                        >
                            <path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
                        </svg>
                    ),
                    badge: "",
                    link: "/bottom-navigation/blocks",
                    items: [
                        {
                            name: "SIP Activation History",
                            link: "/admin/purchased-package-history",
                        },
                        {
                            name: "PNL Distribution History",
                            link: "/admin/pnl-distribution-history",
                        },
                        {
                            name: "PNL History (Short, Long)",
                            link: "/admin/pnl-history",
                        },
                        {
                            name: "SPOT PNL History",
                            link: "/admin/spot-pnl-history",
                        },
                        {
                            name: "Future PNL History",
                            link: "/admin/future-pnl-history",
                        },
                        {
                            name: "Investment PNL History",
                            link: "/admin/investment-pnl-history",
                        },
                        {
                            name: "Holding PNL History",
                            link: "/admin/holding-pnl-history",
                        },
                        {
                            name: "Affiliate PNL History",
                            link: "/admin/affiliate-history",
                        },
                        {
                            name: "Deposit History",
                            link: "/admin/deposit-history",
                        },
                        {
                            name: "Wallet List",
                            link: "/admin/wallet-list",
                        },
                        {
                            name: "User Overview",
                            link: "/admin/user-overview",
                        },
                        {
                            name: "Wallet History",
                            link: "/admin/wallet-history",
                        },
                        {
                            name: "SIP Redeem Request List",
                            link: "/admin/sip-redeem-request-list",
                        },
                        {
                            name: "SIP Redeem Success List",
                            link: "/admin/sip-redeem-success-list",
                        },
                        {
                            name: "NON Distributed ROI History",
                            link: "/admin/non-distributed-history",
                        },
                    ],
                },
                {
                    name: "Withdrawal Reports",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 640 512"
                        >
                            <path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
                        </svg>
                    ),
                    badge: "",
                    link: "/bottom-navigation/blocks",
                    items: [
                        {
                            name: "Withdraw History",
                            link: "/admin/withdrawal-history",
                        },
                        {
                            name: "Withdraw Pending List",
                            link: "/admin/pending-withdrawal-history",
                        },
                        {
                            name: "Withdraw Request List",
                            link: "/admin/withdraw-request-list",
                        },
                        {
                            name: "Withdraw Success List",
                            link: "/admin/withdraw-success-list",
                        },
                    ],
                },
                {
                    name: "Settings",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 512 512"
                        >
                            <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                        </svg>
                    ),
                    link: "/bottom-navigation/blocks",
                    items: [
                        {
                            name: "Common Settings",
                            link: "/admin/settings",
                        },
                        {
                            name: "Unilever Settings",
                            link: "/admin/unilevel-settings",
                        },
                    ],
                },
                {
                    name: "Fake Users",
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            viewBox="0 0 640 512"
                        >
                            <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                        </svg>
                    ),
                    badge: "",
                    link: "/bottom-navigation/blocks",
                    items: [
                        {
                            name: "Fake users",
                            link: "/admin/fake-users",
                        },
                        {
                            name: "Create fake users",
                            link: "/admin/create-fake-user",
                        },
                        {
                            name: "Deposit",
                            link: "/admin/fake-deposit",
                        },
                        {
                            name: "Withdrawal",
                            link: "/admin/fake-withdrawal",
                        },
                        {
                            name: "Package Purchase",
                            link: "/admin/package-purchase",
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <>
            <MainWrapper>
                <MainContent
                    className="main-wrapper"
                    sx={{
                        ".MuiDrawer-hd": {
                            "& .MuiDrawer-paper": {
                                top: 0,
                                width: `calc(320px + ${theme.spacing(4)})`,
                                position: "fixed",
                            },
                        },
                        ".MuiDrawer-fm": {
                            "& .MuiDrawer-paper": {
                                top: 0,
                                position: "fixed",
                            },
                        },
                        ".Mui-FixedWrapper": {
                            ".MuiDrawer-root.MuiDrawer-docked": {
                                ".MuiPaper-root": {
                                    left: 0,
                                    top: 0,
                                },
                            },
                        },
                    }}
                >
                    <Scrollbar>
                        <Outlet />
                    </Scrollbar>
                    <ThemeSettings />
                </MainContent>
                <BottomBar
                    menuItems={
                        props.type == "admin" ? menuItems_admin : menuItems_user
                    }
                />
            </MainWrapper>
            <Drawer
                sx={{
                    display: { lg: "none", xs: "inline-block" },
                }}
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant="temporary"
                elevation={9}
            >
                <SidebarWrapper>
                    <Scrollbar>
                        <TopSection>
                            <Box
                                sx={{
                                    width: 52,
                                    ml: 1,
                                    mt: 1,
                                    mb: 3,
                                }}
                            >
                                <Logo />
                            </Box>
                            <SidebarTopSection />
                        </TopSection>
                        <SidebarMenu menuItems={menuItems_user_res} />
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
};

export default BottomNavigationLayout;