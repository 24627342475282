import { Link } from "react-router-dom";

function Logo() {
  return (
    <Link to="/">
      {localStorage.getItem("appTheme") === "GreenFieldsTheme" ||
      localStorage.getItem("appTheme") === "NebulaFighterTheme" ||
      localStorage.getItem("appTheme") === "DarkSpacesTheme" ? (
        <img
          height={100}
          width={100}
          src="/static/images/logo/logo-dark.svg"
          alt="logo"
        />
      ) : (
        <svg
          width={50}
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 292.69 350.31"
        >
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <g>
                <rect
                  fill="#003"
                  x="49.03"
                  y="295.71"
                  width="11.9"
                  height="39.12"
                />
                <path
                  fill="#003"
                  d="m58.35,278.22c-1.03-.59-2.18-.89-3.45-.89s-2.36.29-3.37.89c-.53.31-1.04.7-1.51,1.17-1.37,1.37-2.06,3.02-2.06,4.96s.68,3.51,2.06,4.88c.24.24.49.46.75.66,1.2.93,2.58,1.4,4.13,1.4s3-.47,4.21-1.4c.26-.2.51-.42.75-.66,1.37-1.37,2.06-3,2.06-4.88s-.69-3.58-2.06-4.96c-.47-.47-.98-.86-1.51-1.17Z"
                />
                <path
                  fill="#003"
                  d="m95.09,305.39v-9.69h-10.75v-17.49h-11.9v17.49h-6.4v9.69h6.4v18c0,3.51,1.02,6.29,3.05,8.35,2.03,2.06,4.86,3.09,8.47,3.09h11.13v-9.91h-8.54c-.61,0-1.13-.22-1.56-.64-.43-.44-.65-.96-.65-1.56v-17.31h10.75Z"
                />
                <path
                  fill="#003"
                  d="m32.98,297.35c-2.82-1.7-6.06-2.55-9.72-2.55-1.58,0-3.06.2-4.46.61-1.4.41-2.67,1-3.81,1.79-1.14.79-2.1,1.71-2.86,2.78h-.23v-20.82H0v35.38c0,4.32.88,8.07,2.63,11.25,1.75,3.18,4.21,5.63,7.36,7.36,3.15,1.73,6.84,2.59,11.06,2.59,3.31,0,6.26-.51,8.85-1.53,2.59-1.02,4.78-2.48,6.56-4.38,1.78-1.91,3.15-4.14,4.12-6.71.97-2.57,1.45-5.43,1.45-8.58,0-3.91-.8-7.33-2.4-10.25-1.6-2.92-3.81-5.24-6.63-6.94Zm-4.08,23.18c-.71,1.55-1.74,2.78-3.09,3.7-1.35.91-2.94,1.37-4.77,1.37s-3.42-.46-4.76-1.37c-1.35-.92-2.39-2.15-3.13-3.7-.74-1.55-1.1-3.29-1.1-5.22s.37-3.64,1.1-5.15c.74-1.5,1.78-2.71,3.13-3.62,1.35-.91,2.94-1.37,4.76-1.37s3.41.46,4.73,1.37c1.32.92,2.35,2.13,3.09,3.62.74,1.5,1.11,3.22,1.11,5.15s-.36,3.67-1.07,5.22Z"
                />
              </g>
              <g>
                <path
                  fill="#f2a900"
                  d="m126.5,72.52c6.15-4.61,12.75-4.34,19.8.72,4.88-7.32,10.4-10.94,16.45-10.76l-10.58-28.12c-10.67,4.52-20.25,4.16-28.57-1.17l-13.74,27.4c6.14.18,12.21,4.16,16.63,11.94Z"
                />
                <path
                  fill="#f2a900"
                  d="m150.37,29.66L139.25,0l-13.47,28.66c7.96,4.97,16.19,5.25,24.6,1Z"
                />
                <path
                  fill="#f2a900"
                  d="m162.75,278.22l.18-30.47c34-1.08,63.29-9.5,60.85-51.09-1-16.37-9.95-26.67-27.58-30.74,13.38-8.23,18.27-20.26,16.27-36.26-2.35-18.99-19.98-30.11-49.64-32.73v-27.94c-5.24.72-9.31,5.24-12.11,9.4l-2.62,3.89-3.71-2.71-.27-.18v17.55l-15.55.18s-.09-6.14,0-18.44l-.09.09-4.16,2.99-2.53-4.43c-2.53-4.53-6.6-9.13-11.93-10.22v29.84h-40.33l-.09,20.16s5.79.09,16.91,0c5.06,0,7.77,3.52,8.14,10.49,0,0-.18,28.94-.72,86.8,0,6.51-2.26,9.67-6.51,9.31,0,0-8.5.18-18.45.09l.18,23.97s15.1.36,40.87,0c0,0-.09,9.85,0,30.47h-25.52v11.67h208.34v-11.67h-129.93Zm-34.36-157.23c29.39-3.44,45.03,2.26,47.11,17.09,2.17,16.09-14.11,23.6-47.29,22.24,0,0,.54-13.11.18-39.33Zm.18,59.85c34.72-2.62,53.26,3.71,54.43,19.17,1.9,25.68-36.25,23.87-54.43,23.78,0,0-.18-15.28,0-42.95Zm15.55,97.38h-15.55v-30.29l15.55-.18v30.47Z"
                />
                <path
                  fill="#f2a900"
                  d="m131.05,297.43c-3.12-1.75-6.83-2.63-11.1-2.63s-8.26.91-11.37,2.75c-3.1,1.83-5.46,4.28-7.09,7.36-1.63,3.08-2.44,6.45-2.44,10.11s.79,7.18,2.37,10.11c1.58,2.92,3.73,5.21,6.45,6.86,2.72,1.65,5.81,2.48,9.27,2.48,1.73,0,3.3-.22,4.73-.65,1.42-.43,2.69-1.04,3.81-1.83,1.12-.79,2.18-1.77,3.2-2.94h.23v8.62c0,.76-.26,1.37-.76,1.83-.51.46-1.14.69-1.91.69h-19.52v10.14h22.42c3.66,0,6.51-1.03,8.54-3.09,2.03-2.06,3.05-4.84,3.05-8.35v-22.88c0-4.27-.87-7.99-2.59-11.17-1.73-3.18-4.15-5.64-7.28-7.4Zm-3.24,22.46c-.72,1.42-1.73,2.56-3.05,3.39-1.32.84-2.9,1.26-4.73,1.26s-3.34-.42-4.69-1.26c-1.35-.84-2.39-1.99-3.13-3.47-.74-1.47-1.11-3.1-1.11-4.88s.37-3.47,1.11-4.92c.74-1.45,1.78-2.62,3.13-3.51,1.35-.89,2.91-1.33,4.69-1.33s3.34.46,4.69,1.37c1.35.92,2.38,2.13,3.09,3.62.71,1.5,1.07,3.17,1.07,4.99,0,1.73-.36,3.31-1.07,4.73Z"
                />
                <path
                  fill="#f2a900"
                  d="m152.14,298.84c-2.03,2.03-3.05,4.83-3.05,8.39v27.61h11.89v-26.84c0-.76.26-1.38.76-1.87.51-.48,1.12-.73,1.83-.73h8.46v-9.61h-11.44c-3.61,0-6.43,1.02-8.46,3.05Z"
                />
                <path
                  fill="#f2a900"
                  d="m207.47,297.54c-3.18-1.78-6.78-2.67-10.79-2.67s-7.56.89-10.79,2.67c-3.23,1.78-5.78,4.2-7.66,7.28-1.88,3.08-2.82,6.55-2.82,10.41s.94,7.42,2.82,10.53c1.88,3.1,4.42,5.54,7.63,7.32s6.81,2.67,10.83,2.67,7.61-.89,10.79-2.67c3.18-1.78,5.69-4.22,7.55-7.32,1.86-3.1,2.78-6.58,2.78-10.45s-.93-7.41-2.78-10.48c-1.86-3.08-4.37-5.5-7.55-7.28Zm-2.71,22.88c-.76,1.53-1.85,2.74-3.24,3.66-1.4.92-3.04,1.37-4.92,1.37s-3.45-.46-4.84-1.37c-1.4-.91-2.48-2.13-3.24-3.66-.76-1.53-1.14-3.25-1.14-5.19s.38-3.58,1.14-5.11c.76-1.53,1.84-2.75,3.24-3.66,1.4-.91,3.01-1.37,4.84-1.37s3.52.46,4.92,1.37c1.4.91,2.48,2.14,3.24,3.66.76,1.53,1.14,3.23,1.14,5.11s-.38,3.66-1.14,5.19Z"
                />
                <path
                  fill="#f2a900"
                  d="m280.56,322.55c0,.61-.16,1.18-.46,1.71-.3.53-.7.96-1.18,1.26-.49.3-1.06.46-1.72.46-.61,0-1.18-.15-1.71-.46-.54-.3-.95-.73-1.26-1.26-.31-.53-.46-1.11-.46-1.71v-13.04c0-3.15-.66-5.82-1.98-8.01-1.32-2.19-3.13-3.84-5.42-4.96-2.29-1.12-4.88-1.68-7.78-1.68s-5.56.56-7.82,1.68c-2.26,1.12-4.07,2.77-5.41,4.96-1.35,2.19-2.02,4.86-2.02,8.01v13.04c0,.61-.15,1.18-.46,1.71-.3.53-.71.96-1.22,1.26-.51.3-1.07.46-1.68.46-.56,0-1.1-.16-1.64-.49-.53-.33-.97-.75-1.3-1.26-.33-.51-.5-1.07-.5-1.68v-26.85h-11.74v25.32c0,3.36.69,6.11,2.06,8.28,1.37,2.16,3.22,3.77,5.53,4.84,2.31,1.07,4.84,1.6,7.59,1.6s5.2-.53,7.51-1.6c2.31-1.07,4.18-2.68,5.61-4.84,1.42-2.16,2.13-4.92,2.13-8.28v-12.96c0-.66.14-1.25.42-1.75.28-.51.68-.92,1.22-1.22.53-.3,1.11-.46,1.72-.46s1.17.16,1.68.46c.51.3.91.71,1.22,1.22.3.51.46,1.09.46,1.75v12.96c0,3.36.71,6.11,2.14,8.28,1.42,2.16,3.29,3.77,5.6,4.84,2.31,1.07,4.82,1.6,7.51,1.6s5.26-.53,7.55-1.6c2.29-1.07,4.13-2.68,5.53-4.84,1.4-2.16,2.1-4.92,2.1-8.28v-25.32h-11.82v26.85Z"
                />
              </g>
            </g>
          </g>
        </svg>
      )}
    </Link>
  );
}

export default Logo;
