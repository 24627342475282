import { Box, Card, Link, Typography, styled } from "@mui/material";

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        py={2}
        display={{ xs: "block", md: "flex" }}
        alignItems="center"
        textAlign={{ xs: "center", md: "left" }}
        justifyContent="center"
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; {new Date().getFullYear()} - Bitgrow
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
