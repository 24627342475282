import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Hidden,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import Label from "src/components/Label";
import { fetchDataWithToken } from "src/config/axios.config";
import ResponsiveTable from "./responsive-table";
import { convertDateTime } from "src/contexts/custom.js";

const getStatusLabel = (cryptoOrderStatus) => {
  const map = {
    in: {
      text: "In",
      color: "success",
    },
    out: {
      text: "Out",
      color: "info",
    },
  };

  const { text, color } = map[cryptoOrderStatus];

  return <Label color={color}>{text}</Label>;
};

const RecentOrdersTable = ({ transactions, setData }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const count = Math.ceil(transactions?.total / transactions?.per_page);
  const handleChangePage = (e, p) => {
    setPage(p);
    fetchDataWithToken(`user/dashboard?page=${p}`, setData);
  };
  return (
    <Card>
      <CardHeader title={t("Recent Activity")} />
      <Divider />
      <Hidden mdUp>
        <ResponsiveTable transactions={transactions} />
      </Hidden>
      <Hidden mdDown>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Description")}</TableCell>
                <TableCell align="center">{t("Amount")}</TableCell>
                <TableCell align="center">{t("TX Type")}</TableCell>
                <TableCell align="center">{t("Date")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.data?.length > 0 ? (
                transactions?.data?.map((history) => {
                  return (
                    <TableRow hover key={history?.id}>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {history?.reason}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          ${history?.amount?.toFixed(4)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {getStatusLabel(history?.type)}
                      </TableCell>
                      <TableCell align="center">
                        {convertDateTime(history?.created_at)}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover>
                  <TableCell colSpan={7} align="center">
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      {transactions?.total > transactions?.per_page && (
        <Grid
          className={`material-pagination ${
            (localStorage.getItem("appTheme") == "GreenFieldsTheme" &&
              "GreenFieldsTheme") ||
            (localStorage.getItem("appTheme") == "NebulaFighterTheme" &&
              "NebulaFighterTheme") ||
            (localStorage.getItem("appTheme") == "DarkSpacesTheme" &&
              "DarkSpacesTheme") ||
            (localStorage.getItem("appTheme") == "PureLightTheme" &&
              "PureLightTheme") ||
            (localStorage.getItem("appTheme") == "GreyGooseTheme" &&
              "GreyGooseTheme") ||
            (localStorage.getItem("appTheme") == "PurpleFlowTheme" &&
              "PurpleFlowTheme")
          }`}
        >
          <Pagination
            containerClassName="paginationWrapper"
            count={count}
            page={page}
            variant="outlined"
            shape="rounded"
            size="medium"
            onChange={handleChangePage}
            className="paginationWrap mt-0"
            siblingCount={0}
            boundaryCount={2}
          />
        </Grid>
      )}
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  cryptoOrders: PropTypes.array.isRequired,
};

RecentOrdersTable.defaultProps = {
  cryptoOrders: [],
};

export default RecentOrdersTable;
